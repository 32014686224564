import AboutSection from "../../component/organism/About";
const About = () => {

    return (
        <div>
            <AboutSection/>
        </div>
    );
};

export default About;