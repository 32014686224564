import styles  from "./style.module.scss";

import errorImg from "../../../../assets/backgronds/emptyPage/EmptyDesktop.png";
import {useSelector} from "react-redux";

const SearchError = ({lang}) => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);

    if(isMobile){
        return (
            <div className={styles.main_section_div_mobile}>
                <div className={styles.section_paragraph_mobile}>
                <span>
                    {lang.searchError.red}
                </span>
                    {lang.searchError.regular}
                </div>
                <img src={errorImg} alt={"meat"} className={styles.section_img_mobile}/>
            </div>
        );
    }
    return (
        <div className={styles.main_section_div}>
            <div className={styles.section_paragraph}>
                <span>
                    {lang.searchError.red}
                </span>
                {lang.searchError.regular}
            </div>
            <img src={errorImg} alt={"meat"} className={styles.section_img}/>
        </div>
    );
};

export default SearchError;