import styles from "./style.module.scss";

import {Rating} from "@mui/material";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {gql, useMutation} from "@apollo/client";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {openPopup} from "../../../redux/features/popup/openLoginPopupSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {notifyUser} from "../../../redux/features/popup/notifyMessage";


const ADD_REVIEW_TO_ITEM = gql`
    mutation Mutation(
        $reviewInput: ReviewInput
    ) {
        addNewReview(
          reviewInput: $reviewInput
        ) {
              _id
              userName
              rating
              date
              comment
              collectionLength
        }
    }
`;


const useStyles = makeStyles((theme) => ({
    emptyStar: {
        color: "#730101"
    }
}));

const ReviewInputField = ({setSkip, refetch, lang}) => {
    const classes = useStyles();
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const user = isAuthenticated ? stateUser.user : {};

    const isMobile = useSelector(state => state.screenVersion.isMobile);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [inputValue, setInputValue] = useState("");
    const [rating, setRating] = useState(0);

    const inputRef = useRef(null);

    const {pathname} = useLocation();

    useEffect(() => {
        inputRef.current.value = '';
        setRating(0);

    }, [pathname]);

    const [addReviewToItem] = useMutation(ADD_REVIEW_TO_ITEM, {
        update: async (cache, {data: {addNewReview}}) => {
            inputRef.current.value = '';
            setRating(0);
            await refetch();
            dispatch(notifyUser({
                type: "success",
                barOpen: true,
                message: lang.successMessage
            }))
        },
    });
    const handleSubmit = async () => {
        if (isAuthenticated){
            isMobile ? refetch() : setSkip();
            await addReviewToItem({
                variables: {
                    reviewInput: {
                        userName: user.fullName,
                        userId: user._id,
                        rating: Number(rating),
                        comment: inputValue
                    }
                },
            })
        }else {
            if (!isAuthenticated && !isMobile) {
                dispatch(openPopup({
                    popupOpen: true,
                    fromNavbar: "withoutRouting",
                }));
            } else if (!isAuthenticated && isMobile) {
                navigate("/sign_in")
            }
        }
    }
    return (
        <div className={styles.input_field_with_button}>
            <div className={styles.input_section}
                 onKeyDown={(event) => {

                     if (event.key === 'Enter') {
                         event.preventDefault();
                         handleSubmit();
                     }
                 }}
                 onClick={() => {
                     if (!isAuthenticated && !isMobile) {
                         dispatch(openPopup({
                             popupOpen: true,
                             fromNavbar: "withoutRouting",
                         }));
                     } else if (!isAuthenticated && isMobile) {
                         navigate("/sign_in")
                     }
                 }}>
                <div className={styles.blur_background}/>
                <input
                    type={"text"}
                    autoComplete={"off"}

                    className={styles.input}
                    ref={inputRef}
                    placeholder={lang && lang.inputPlaceholder}
                    maxLength={160}
                    onChange={(event) => {
                        setInputValue(event.target.value);
                    }}/>
                <Rating
                    name="rating"
                    defaultValue={0}
                    value={Number(rating)}
                    precision={0.5}
                    onChange={(event) => {
                        setRating(event.target.value);
                    }}
                    sx={{
                        color: '#730101',
                        borderColor: '#730101'
                    }}
                    emptyIcon={
                        <StarBorderIcon fontSize="inherit" className={classes.emptyStar}/>
                    }
                />
            </div>
            <button className={styles.send_btn} onClick={handleSubmit}>
                {lang && lang.addCommentBtn}
            </button>
        </div>
    );
};

export default ReviewInputField;