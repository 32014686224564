import { createSlice } from '@reduxjs/toolkit';
import recentlyViewedProducts from "../../../component/molecul/ProductPage/RecentlyViewedProducts";

const addRecentlyViewedSlice = createSlice({
  name: 'recentlyViewed',
  initialState: {
    recentlyViewed: !!localStorage.getItem('recentlyViewed') ?  JSON.parse(localStorage.getItem('recentlyViewed')) : [],
  },
  reducers: {
    addRecentlyViewedItem: (state, action) => {
      const item = action.payload;
      const recentlyViewedArray = state.recentlyViewed;
      const arrayString = recentlyViewedArray.map(JSON.stringify);
      const itemString = JSON.stringify(item);
      const ifIncludes = arrayString.includes(itemString);

      if(!ifIncludes){
        const newRecentlyViewed = [item, ...recentlyViewedArray.slice(0,8)];
        state.recentlyViewed = newRecentlyViewed;
        localStorage.setItem('recentlyViewed', JSON.stringify(newRecentlyViewed));
      }else{
        const updatedProductIds = [...recentlyViewedArray]

        const index = arrayString.indexOf(itemString);

        if (index !== -1) {
          updatedProductIds.splice(index, 1);
          const newRecentlyViewed = [item, ...updatedProductIds];
          state.recentlyViewed = newRecentlyViewed;
          localStorage.setItem('recentlyViewed', JSON.stringify(newRecentlyViewed));
        }

      }
    },
  },
});

export const { addRecentlyViewedItem } = addRecentlyViewedSlice.actions;

export default addRecentlyViewedSlice.reducer;
