import {useLocation, useNavigate} from "react-router-dom";
import styles from "./style.module.scss";
import emptyMobile from "../../../../assets/backgronds/emptyPage/EmptyMobile.png";

const EmptyPageMobile = ({lang}) =>{
    const location = useLocation().pathname;
    const navigate = useNavigate();

    return(
        <div className={styles.main_section}>
            <div className={styles.section_heading}>
                <hr/>
                    <h1>
                        {lang[location].heading}
                    </h1>
                <hr/>
            </div>
            <div className={styles.flex_column_divs}>
                <img src={emptyMobile} alt={"img"}/>
                <div className={styles.paragraph_div}>
                    <span>
                        {lang[location].paragraph}
                    </span>
                    <button
                        className={styles.back_btn}
                        onClick={() => {
                            navigate("/catalog")
                        }}
                    >
                        {lang[location].buyBtn}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EmptyPageMobile;