import SettingsSection from "../../component/organism/Settings";

const Settings = () => {
    return(
        <div>
            <SettingsSection />
        </div>
    );
};

export default Settings;