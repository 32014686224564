import styles from './style.module.scss';


import {useState} from 'react';
import {useSelector} from "react-redux";

const Toolbar = ({value, changeState, lang}) => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const [state, setState] = useState(value.state && value.state);

    if (isMobile) {
        return (
            <div className={styles.tool_bar_div_mobile}>
                <div className={styles.product_state_choose_mobile}>
                    <div className={state === "raw" ?  styles.product_state_active_mobile : styles.product_state_inactive_mobile}
                         onClick={() => {
                             setState("raw");
                             changeState({
                                 ...value,
                                 state: "raw"
                             });
                         }}>
                        <span>{lang.rawBtn}</span>
                    </div>
                    <div className={state === "cooked" ? styles.product_state_active_mobile : styles.product_state_inactive_mobile}
                         onClick={() => {
                             setState("cooked")
                             changeState({
                                 ...value,
                                 state: "cooked"
                             });
                         }}>
                        <span>{lang.cookedBtn}</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.tool_bar_div}>
            <div className={styles.product_state_choose}>
                <div className={state === "raw" ? styles.product_state_active : styles.product_state_inactive}
                     onClick={() => {
                         setState("raw");
                         changeState({
                             ...value,
                             state: "raw"
                         });
                     }}>
                    <span>{lang.rawBtn}</span>
                </div>
                <div className={state === "cooked" ? styles.product_state_active : styles.product_state_inactive}
                     onClick={() => {
                         setState("cooked")
                         changeState({
                             ...value,
                             state: "cooked"
                         });
                     }}>
                    <span>{lang.cookedBtn}</span>
                </div>
            </div>
        </div>
    );
};

export default Toolbar;

