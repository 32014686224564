import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import {useSelector} from "react-redux";

import {
    HomePage,
    LoginPage,
    FavoritePage,
    CartPage,
    ProductPage,
    CheckoutPage,
    CatalogPage,
    AboutPage,
    ContactUs,
    OrdersPage,
    OrderDonePage,
    SettingsPage
} from "./pages";

import DefaultLayout from "./layouts/Default";


export const useRoutes = () => {

    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);


    return (
        <BrowserRouter>
            <Routes>
                {!isAuthenticated ?

                    <Route>
                        <Route exact path='/sign_in' element={<LoginPage/>}></Route>
                        <Route exact path='/sign_in/:registration' element={<LoginPage/>}></Route>
                        <Route exact path='*' element={<Navigate to="/"/>}></Route>
                    </Route>

                    :

                    <Route path="/" element={<DefaultLayout/>}>
                        <Route exact index element={<HomePage/>}></Route>
                        <Route exact path='/cart' element={<CartPage/>}></Route>
                        <Route exact path='/profile_settings' element={<SettingsPage/>}></Route>
                        <Route exact path='/Wishlist' element={<FavoritePage/>}></Route>
                        <Route exact path='/my_orders' element={<OrdersPage/>}></Route>
                        <Route exact path='*' element={<Navigate to="/"/>}></Route>
                    </Route>

                }
                <Route path="/" element={<DefaultLayout/>}>
                    <Route exact index element={<HomePage/>}></Route>
                    <Route exact path='catalog/:productId' element={<ProductPage/>}></Route>
                    <Route exact path='/checkout' element={<CheckoutPage/>}></Route>
                    <Route exact path='/catalog' element={<CatalogPage/>}></Route>
                    <Route exact path='/about_us' element={<AboutPage/>}></Route>
                    <Route exact path='/contact_us' element={<ContactUs/>}></Route>
                    <Route exact path='/order_done' element={<OrderDonePage/>}></Route>
                    <Route exact path='/order_done/ameriabank' element={<OrderDonePage/>}></Route>
                    <Route exact path='*' element={<Navigate to="/"/>}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};