import { createSlice } from '@reduxjs/toolkit';

const changeLangSlice = createSlice({
  name: 'changeLang',
  initialState: {
    lang: !!localStorage.getItem('userLang') ?  JSON.parse(localStorage.getItem('userLang')) : "ENG",
  },
  reducers: {
    changeLang: (state, action) => {
      localStorage.setItem('userLang', JSON.stringify(action.payload));
        state.lang = action.payload;
        if(action.payload === "RU"){
          document.documentElement.style.setProperty('--app-fontFamily', 'Montserrat');
        }else {
          document.documentElement.style.setProperty('--app-fontFamily', 'Mardoto');
        }
    },
  },
});

export const { changeLang } = changeLangSlice.actions;

export default changeLangSlice.reducer;
