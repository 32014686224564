import {gql, useQuery} from '@apollo/client';

import {useSelector} from 'react-redux';

import ProductSlider from "../../ProductSlider";
import styles from "../style.module.scss";

const GET_SIMILAR_PRODUCTS = gql`
  query($userId: ID!, $type: String!, $currentItemId: ID!){
    productsWithType(type: $type, currentItemId: $currentItemId) {
      _id
      state
      productTitle{
        en
        am
        ru
      }
      type
      price
      productImages{
        productSmallRawImage
        productSmallCookedImage
      }
    }
    userFavoriteProducts(userId: $userId){
      _id
      productId
      state
    }
    userCartProducts(userId: $userId){
      _id
      price
      quantity
      productId
      productImages
      productTitle{
          en
          am
          ru
      }
      defaultPrice
      state
      }
  }
`;

const SimilarProductSection = ({type, currentItemId, lang}) => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const userId = isAuthenticated ? stateUser.user._id : '';
    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const {loading, error, data} = useQuery(GET_SIMILAR_PRODUCTS, {
        variables: {
            userId: userId,
            type: type,
            currentItemId: currentItemId
        }
    });

    return (
        <div className={isMobile ? styles.main_section_mobile : styles.main_section}>
            {
                !loading && !error &&  data.productsWithType.length !== 0 &&
                <div className={isMobile ? styles.section_heading_mobile : styles.section_heading}>
                    <div>
                        <hr/>
                        <h2>
                            {lang.headingSimilarSection}
                        </h2>
                    </div>
                </div>
            }

            <ProductSlider
                data={data}
                loading={loading}
                error={error}
                products={data ? data.productsWithType : undefined}
                favorites={data ? data.userFavoriteProducts : undefined}
                cartProducts={data ? data.userCartProducts : undefined}
                sliderHeading={lang.headingSimilarSection}
                fromSimilar={true}
            />
        </div>
    )
};

export default SimilarProductSection;