import styles from "./style.module.scss";

import rawImage from "../../../../assets/backgronds/home/heroSection/rawImg.png";
import cookedImage from "../../../../assets/backgronds/home/heroSection/cookedImg.png";

import specialOffersImg from "../../../../assets/icons/homePage/commonIcons/specialOffers.png";

import {useEffect, useRef, useState} from "react";

import en from "../../../../assets/lang/en/HomePage/HeroSection/en.json";
import am from "../../../../assets/lang/am/HomePage/HeroSection/en.json";
import ru from "../../../../assets/lang/ru/HomePage/HeroSection/en.json";

import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import SearchBar from "../../../molecul/Layout/SearchBar";

const HeroSection = () => {
    const [rawSectionSize, setRawSectionSize] = useState(window.innerWidth / 2);
    const isMobile = useSelector((state) => state.screenVersion.isMobile);

    const lang = useSelector(state => state.globalLang.lang);
    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);
    const rawSectionRef = useRef(null);

    const handler = (mouseDownEvent) => {
        rawSectionRef.current.style.transitionDuration = "";
        const startSize = rawSectionSize;
        const startPosition = mouseDownEvent.pageX;

        function onMouseMove(mouseMoveEvent) {
            setRawSectionSize(startSize - startPosition + mouseMoveEvent.pageX);
        }

        function onMouseUp() {
            document.body.removeEventListener("mousemove", onMouseMove);
            // uncomment the following line if not using `{ once: true }`
            document.body.removeEventListener("mouseup", onMouseUp);
        }

        document.body.addEventListener("mousemove", onMouseMove);
        document.body.addEventListener("mouseup", onMouseUp, {once: true});
    };

    const handleResize = () => {
        setRawSectionSize(window.innerWidth / 2)
    }
    useEffect(() => {
        let interval
        let timeoutId

        window.addEventListener('resize', handleResize);
        if (!isMobile) {
            const screenWidth = window.innerWidth;
            interval = setInterval(() => {

                rawSectionRef.current.style.transitionDuration = "500ms";
                setRawSectionSize(screenWidth / 2 - 100)
                setTimeout(() => {
                    setRawSectionSize(screenWidth / 2 + 100)
                }, 500)
            }, 1000);

            timeoutId = setTimeout(() => {
                rawSectionRef.current.style.transitionDuration = 0;
                clearInterval(interval);
                setTimeout(() => {
                    setRawSectionSize(screenWidth / 2)
                }, 500)


            }, 2000);
        }

        return () => {
            clearInterval(interval);
            clearTimeout(timeoutId)
            window.removeEventListener('resize', handleResize);
        }

    }, []);

    const navigate = useNavigate();


    if (isMobile) {
        return (
            <div className={styles.container_mobile}>

                <div className={styles.left_mobile} ref={rawSectionRef}>
                    <div className={styles.search_bar_div}>
                        <SearchBar/>
                    </div>
                    <div
                        className={styles.raw_section_heading_mobile}
                        onClick={() => {
                            navigate("/catalog", {
                                state: {
                                    productState: "raw"
                                }
                            })
                        }}
                    >
                        <div>
                            <hr/>
                            <h2>
                                {langObj.rawSectionHeading}
                            </h2>
                        </div>
                    </div>
                </div>
                <div className={styles.right_mobile}>
                    <div
                        className={styles.cooked_section_heading_mobile}
                        onClick={() => {
                            navigate("/catalog", {
                                state: {
                                    productState: "cooked"
                                }
                            })
                        }}
                    >
                        <div>
                            <h2>
                                {langObj.cookedSectionHeading}
                            </h2>
                            <hr/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.hero_section}>
            <div className={styles.container}>
                <div
                    className={styles.cooked_section_heading}
                    onClick={() => {
                        navigate("/catalog", {
                            state: {
                                productState: "cooked"
                            }
                        })
                    }}
                >
                    <div>
                        <h2>
                            {langObj.cookedSectionHeading}
                        </h2>
                        <hr/>
                    </div>
                </div>
                <div className={styles.left} style={{width: rawSectionSize}} ref={rawSectionRef}>
                    <img src={rawImage} alt={'rawImage'} className={styles.raw_img}/>
                    <div className={styles.left_section}>
                        <div className={styles.raw_nav_div}>
                            <button
                                className={styles.special_offers_btn}
                                onClick={() => {
                                    navigate('/catalog', {
                                        state: {
                                            specialOffers: true,
                                        }
                                    });
                                }}
                            >
                                <img src={specialOffersImg} alt={'specialOffers'}/>
                                <span>{langObj.specialOffersBtn}</span>
                            </button>
                            <span onClick={() => {
                                navigate('/catalog')
                            }}>
                                {langObj.menuBtn}
                            </span>
                            <span onClick={() => {
                                navigate('/about_us')
                            }}>
                                {langObj.aboutPageBtn}
                            </span>
                            <span onClick={() => {
                                navigate('/contact_us')
                            }}
                            >
                                {langObj.contactsBtn}
                            </span>
                            <span>
                                {langObj.blogBtn}
                            </span>

                        </div>
                        <div
                            className={styles.raw_section_heading}
                            onClick={() => {
                                navigate("/catalog", {
                                    state: {
                                        productState: "raw"
                                    }
                                })
                            }}
                        >
                            <div>
                                <hr/>
                                <h2>
                                    {langObj.rawSectionHeading}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className={styles.middle} onMouseDown={handler}/>
                </div>

                <div className={styles.right}>
                    <img src={cookedImage} alt={"cookedImg"} className={styles.cooked_img}/>
                </div>
            </div>
        </div>
    )
        ;
};

export default HeroSection;