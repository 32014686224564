import {DesktopCatalogSection, MobileCatalogSection} from "../../component/organism/Catalog";
import {useSelector} from "react-redux";
import {useEffect} from "react";

const Catalog = () => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    useEffect(()=>{
        window.scrollTo(0 , 0);
    }, [])
    return (
        <div>
            {isMobile ? <MobileCatalogSection/> : <DesktopCatalogSection/>}
        </div>
    );
};

export default Catalog;