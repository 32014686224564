import styles from "./style.module.scss";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useState} from "react";

const CustomLoginInput = ({
                              onChange,
                              onClick,
                              onKeyDown,
                              style,
                              className,
                              id,
                              value,
                              defaultValue,
                              placeholder,
                              type,
                              name,
                              customInputHeight,
                              min,
                              max,
                              maxLength,
    ref
                          }) => {
    const [inputType, setInputType] = useState(type);

    const handleTypeChange = () => {
        if (inputType === "password") {
            setInputType("text")
            return;
        }
        setInputType("password")
    }


    return (
        <div className={styles.customInputDiv} style={{
            height: customInputHeight && customInputHeight,
        }}>
            {type === 'number' &&
                <span className={styles.tel_span}> +374</span>}
            <input
                onChange={onChange}
                onClick={onClick}
                onKeyDown={onKeyDown}
                style={style}
                className={type === "email" ? className + " " + styles.email_input : className + " " + styles.customInput}
                id={id}
                name={name}
                value={value}
                defaultValue={defaultValue}
                placeholder={placeholder}
                type={inputType}
                autoComplete="off"
                min={min}
                max={max}
                maxLength={maxLength}
            />
            {type === 'password' &&
                <span onClick={handleTypeChange}>{inputType === 'text' ? <Visibility/> : <VisibilityOff/>}</span>}

        </div>
    )
}


export default CustomLoginInput;