import styles from './style.module.scss';

import AboutSectionDesktop from "./Desktop";
import AboutSectionMobile from "./Mobile";
import FaqSection from "../Home/FaqSection";
import {useSelector} from "react-redux";
import backgroundImgDesktop from "../../../assets/backgronds/aboutUs/desktopFaq.png";
import backgroundImgMobile from "../../../assets/backgronds/aboutUs/mobileFaq.png";
import {useEffect, useState} from "react";
import en from "../../../assets/lang/en/AboutPage/en.json";
import am from "../../../assets/lang/am/AboutPage/en.json";
import ru from "../../../assets/lang/ru/AboutPage/en.json";

const AboutSection = () => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    useEffect(()=>{
        window.scrollTo(0 , 0);
    }, []);

    const stateLang = useSelector(state => state.globalLang.lang);


    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (stateLang === "ARM") {
            setLangObj(am);
        } else if (stateLang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [stateLang]);
    return (
        <div style={{
            boxSizing: 'border-box',
            paddingBottom: "45px",
        }}>
            {
                isMobile
                    ?
                    <AboutSectionMobile lang={langObj}/>
                    :
                    <AboutSectionDesktop lang={langObj}/>
            }
            {isMobile ?
                <img src={backgroundImgMobile} alt={'backgroundImg'} className={styles.faq_background_mobile}/>
                :
                <img src={backgroundImgDesktop} alt={'backgroundImg'} className={styles.faq_background_desktop}/>
            }
            <FaqSection/>
        </div>
    );
};

export default AboutSection;