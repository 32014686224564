import styles from './style.module.scss';

import en from "../../../assets/lang/en/Settings/en.json";
import am from "../../../assets/lang/am/Settings/en.json";
import ru from "../../../assets/lang/ru/Settings/en.json";

import {useDispatch, useSelector} from "react-redux";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useEffect, useRef, useState} from "react";
import {gql} from "graphql-tag";
import {useMutation} from "@apollo/react-hooks";
import {loginSuccess} from "../../../redux/features/user/userSlice";
import {useNavigate} from "react-router-dom";

import {notifyUser} from "../../../redux/features/popup/notifyMessage";

const UPDATE_USER_DATA = gql`
    mutation Mutation(
        $userDataInput: UserDataInput
    ) {
        updateUserData(
            userDataInput:  $userDataInput
        ) {
            token
        }
    }
`;

const SettingsSection = () => {
    const user = useSelector(state => state.user).user;
    const userId = user ? user._id : "without account";
    const dispatch = useDispatch();

    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const [newPassInputDisabled, setNewPassInputDisabled] = useState(true);
    const [confirmPassInputDisabled, setConfirmPassInputDisabled] = useState(true);

    const [confirmPassword, setConfirmPassword] = useState('');
    const [fieldVisibility, setFieldVisibility] = useState({
        oldPassword: "password",
        newPassword: "password",
        confirmPassword: "password",
    });

    const navigate = useNavigate();

    const confirmPassRef = useRef(null);
    const newPassRef = useRef(null);

    const lang = useSelector(state => state.globalLang.lang);

    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);

    const [changeData, setChangeData] = useState({
        userId: userId,
        newPassword: '',
        oldPassword: '',
        fullName: '',
        email: '',
        tel: '',
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (changeData.oldPassword.length > 0) {
            setNewPassInputDisabled(false);
        } else {
            setNewPassInputDisabled(true);
            setConfirmPassInputDisabled(true);
            newPassRef.current.value = '';
            confirmPassRef.current.value = '';

            setConfirmPassword('');
            setChangeData({
                ...changeData,
                newPassword: "",
            })
        }
    }, [changeData.oldPassword]);

    useEffect(() => {
        if (changeData.newPassword.length > 0) {
            setConfirmPassInputDisabled(false);
        } else {
            setConfirmPassInputDisabled(true);
            confirmPassRef.current.value = '';
            setConfirmPassword('');
        }
    }, [changeData.newPassword]);

    const [updateUserData, {loading}] = useMutation(UPDATE_USER_DATA, {
        update(proxy, {data: {updateUserData: userData}}) {
            const token = userData.token;
            dispatch(loginSuccess(token));

            localStorage.setItem('token', token);
            navigate("/");
        },
        onError({graphQLErrors}) {
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                const message = JSON.parse(graphQLErrors[0].message);
                dispatch(notifyUser({
                    barOpen: true,
                    message: lang === "ARM" ? message.am : lang === "RU" ? message.ru : message.en,
                }));
            }
        },
        variables: {
            userDataInput: {
                ...changeData,
                email: changeData.email === user.email ? '' : changeData.email,
                tel: changeData.tel === user.tel ? '' : changeData.tel,
            }
        }
    })
    const changeHandler = (event) => {
        setChangeData({...changeData, [event.target.name]: event.target.value});
    };
    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }
    const handleSaveChanges = async () => {
        if (changeData.oldPassword.length !== 0 && changeData.newPassword.length !== 0 && confirmPassword.length !== 0 && !loading) {
            if (changeData.newPassword !== confirmPassword) {
                dispatch(notifyUser({
                    barOpen: true,
                    message: langObj.unlikePassword,
                }));
            } else {
                if (changeData.email.length !== 0 && !isValidEmail(changeData.email)) {
                    dispatch(notifyUser({
                        barOpen: true,
                        message: langObj.wrongEmailWarning,
                    }));
                } else if (changeData.tel.length !== 0 && changeData.tel.length !== 8) {
                    dispatch(notifyUser({
                        barOpen: true,
                        message: langObj.wrongTelWarning,
                    }));
                } else {
                    await updateUserData();
                }
            }
        } else if (changeData.oldPassword.length === 0 && changeData.newPassword.length === 0 && confirmPassword.length === 0 && !loading) {
            if (changeData.email.length !== 0 && !isValidEmail(changeData.email)) {
                dispatch(notifyUser({
                    barOpen: true,
                    message: langObj.wrongEmailWarning,
                }));
            } else if (changeData.tel.length !== 0 && changeData.tel.length !== 8) {
                dispatch(notifyUser({
                    barOpen: true,
                    message: langObj.wrongTelWarning,
                }));
            } else await updateUserData();
        } else {
            dispatch(notifyUser({
                barOpen: true,
                message: langObj.emptyPasswordField,
            }));
        }
    }

    if (isMobile) {
        return (
            <div className={styles.main_section_mobile}>
                <h1 className={styles.section_heading_mobile}>
                    {langObj.sectionHeading}
                </h1>
                <div className={styles.settings_section_mobile}>

                    <div className={styles.input_div}>
                        <label>{langObj.nameLabel}</label>
                        <input type={"text"} name={"fullName"} defaultValue={user ? user.fullName : ''}
                               onChange={changeHandler}/>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.emailLabel}</label>
                        <input type={"text"} name={"email"} className={styles.email_input}
                               defaultValue={user ? user.email : ''}
                               onChange={changeHandler}/>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.telLabel}</label>
                        <div className={styles.tel_input_div}>
                            <span className={styles.tel_span}>+374</span>
                            <input
                                type={"number"}
                                name={"tel"}
                                defaultValue={user ? user.tel : ''}
                                onChange={changeHandler}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === '.' || e.key === '+') e.preventDefault();
                                    else if (e.target.value.length > 7 && e.key !== 'Backspace' && e.key !== 'Control' && e.key !== 'a') e.preventDefault();
                                }}
                            />
                        </div>
                    </div>


                    <div className={styles.input_div}>
                        <label>{langObj.oldPassLabel}</label>
                        <div className={styles.password_input_div}>
                            <input
                                type={fieldVisibility.oldPassword}
                                autoComplete={"new-password"}
                                className={styles.password_input}
                                name={"oldPassword"}
                                onChange={changeHandler}
                            />
                            <span onClick={() => {
                                if (fieldVisibility.oldPassword === 'password') {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        oldPassword: "text",
                                    })
                                } else {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        oldPassword: "password",
                                    })
                                }
                            }}>{fieldVisibility.oldPassword === 'text' ? <Visibility/> :
                                <VisibilityOff/>}</span>
                        </div>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.newPassLabel}</label>
                        <div className={styles.password_input_div}>
                            <input
                                type={fieldVisibility.newPassword}
                                autoComplete={"new-password"}
                                className={styles.password_input}
                                ref={newPassRef}
                                name={"newPassword"}
                                onChange={changeHandler}
                                readOnly={newPassInputDisabled}
                            />
                            <span onClick={() => {
                                if (fieldVisibility.newPassword === 'password') {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        newPassword: "text",
                                    })
                                } else {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        newPassword: "password",
                                    })
                                }
                            }}>{fieldVisibility.newPassword === 'text' ? <Visibility/> :
                                <VisibilityOff/>}</span>
                        </div>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.confirmPassLabel}</label>
                        <div className={styles.password_input_div}>
                            <input
                                type={fieldVisibility.confirmPassword}
                                autoComplete={"new-password"}
                                className={styles.password_input}
                                ref={confirmPassRef}
                                readOnly={confirmPassInputDisabled}
                                onChange={(event) => {
                                    setConfirmPassword(event.target.value);
                                }}
                            />
                            <span onClick={() => {
                                if (fieldVisibility.confirmPassword === 'password') {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        confirmPassword: "text",
                                    })
                                } else {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        confirmPassword: "password",
                                    })
                                }
                            }}>{fieldVisibility.confirmPassword === 'text' ? <Visibility/> :
                                <VisibilityOff/>}</span>
                        </div>
                    </div>
                    <button className={styles.save_btn_mobile} onClick={handleSaveChanges}>
                        {langObj.saveBtn}
                    </button>
                </div>
            </div>
        );
    }
    return (
        <div className={styles.main_section}>
            <h1 className={styles.section_heading}>
                {langObj.sectionHeading}
            </h1>
            <div className={styles.settings_section}>
                <div className={styles.section_row}>
                    <div className={styles.input_div}>
                        <label>{langObj.nameLabel}</label>
                        <input type={"text"} name={"fullName"} defaultValue={user ? user.fullName : ''}
                               onChange={changeHandler}/>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.emailLabel}</label>
                        <input type={"text"} name={"email"} className={styles.email_input}
                               defaultValue={user ? user.email : ''}
                               onChange={changeHandler}/>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.telLabel}</label>
                        <div className={styles.tel_input_div}>
                            <span className={styles.tel_span}>+374</span>
                            <input
                                type={"number"}
                                name={"tel"}
                                defaultValue={user ? user.tel : ''}
                                onChange={changeHandler}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === '.' || e.key === '+') e.preventDefault();
                                    else if (e.target.value.length > 7 && e.key !== 'Backspace' && e.key !== 'Control' && e.key !== 'a') e.preventDefault();
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.section_row}>
                    <div className={styles.input_div}>
                        <label>{langObj.oldPassLabel}</label>
                        <div className={styles.password_input_div}>
                            <input
                                type={fieldVisibility.oldPassword}
                                autoComplete={"new-password"}
                                className={styles.password_input}
                                name={"oldPassword"}
                                onChange={changeHandler}
                            />
                            <span onClick={() => {
                                if (fieldVisibility.oldPassword === 'password') {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        oldPassword: "text",
                                    })
                                } else {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        oldPassword: "password",
                                    })
                                }
                            }}>{fieldVisibility.oldPassword === 'text' ? <Visibility/> :
                                <VisibilityOff/>}</span>
                        </div>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.newPassLabel}</label>
                        <div className={styles.password_input_div}>
                            <input
                                type={fieldVisibility.newPassword}
                                autoComplete={"new-password"}
                                className={styles.password_input}
                                ref={newPassRef}
                                name={"newPassword"}
                                onChange={changeHandler}
                                readOnly={newPassInputDisabled}
                            />
                            <span onClick={() => {
                                if (fieldVisibility.newPassword === 'password') {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        newPassword: "text",
                                    })
                                } else {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        newPassword: "password",
                                    })
                                }
                            }}>{fieldVisibility.newPassword === 'text' ? <Visibility/> :
                                <VisibilityOff/>}</span>
                        </div>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.confirmPassLabel}</label>
                        <div className={styles.password_input_div}>
                            <input
                                type={fieldVisibility.confirmPassword}
                                autoComplete={"new-password"}
                                className={styles.password_input}
                                ref={confirmPassRef}
                                readOnly={confirmPassInputDisabled}
                                onChange={(event) => {
                                    setConfirmPassword(event.target.value);
                                }}
                            />
                            <span onClick={() => {
                                if (fieldVisibility.confirmPassword === 'password') {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        confirmPassword: "text",
                                    })
                                } else {
                                    setFieldVisibility({
                                        ...fieldVisibility,
                                        confirmPassword: "password",
                                    })
                                }
                            }}>{fieldVisibility.confirmPassword === 'text' ? <Visibility/> :
                                <VisibilityOff/>}</span>
                        </div>
                    </div>
                </div>
                <button className={styles.save_btn} onClick={handleSaveChanges}>
                    {langObj.saveBtn}
                </button>
            </div>
        </div>
    );
};

export default SettingsSection;