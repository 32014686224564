import { useState, useEffect } from 'react';


import styles from './style.module.scss';


import prevIcon from '../../../assets/icons/homePage/commonIcons/prevIcon.png';
import nextIcon from '../../../assets/icons/homePage/commonIcons/nextIcon.png';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {useSelector} from 'react-redux';

import Skeleton from '@mui/material/Skeleton';
import ProductCart from '../ProductCart';

const ProductSlider = ({ loading, error, products, favorites, cartProducts, state, fromSimilar, fromRecently}) => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const [swiper, setSwiper] = useState();

    const [elCount, setElCount] = useState(()=>{
        return window.innerWidth > 1440
            ?4
            : window.innerWidth >= 1024
                ? 3
                : window.innerWidth >= 768
                    ? 2
                    : 1.32

    });


    const  handleResize = (event) => {
        const width = event.target.innerWidth
        if(width > 1480){
            setElCount(4)
        }else if(width > 1024 && width < 1480){
            setElCount(3)
        }else if(width > 768 && width<=1024){
            setElCount(2)
        }else{
            setElCount(1.32)
        };
    };

    useEffect(()=>{
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])


    if (loading) return (
        <div>
            <Skeleton />
        </div>
    );
    if (error) return <p>Error :(</p>;

    if(products.length === 0){
        return null;
    }
    const slidesPerView = Math.min(products.length, elCount);
    return(
        <div className={isMobile ? styles.product_section_mobile : styles.product_section}>
            <div className={styles.heading_btn_div}>
                    <button className={isMobile ? styles.display_none : styles.swiper_nav_btn} onClick={()=>{
                        swiper.slidePrev();
                    }} >
                        <img  width="17" height="30" src={prevIcon} alt="prev" />
                    </button>
                <Swiper
                    preloadImages={false}
                    lazy={true}
                    onSwiper={(swiper) => {
                        setSwiper(swiper);
                    }}
                    slidesPerView={slidesPerView}
                    threshold={isMobile ? 0 : 50}
                    spaceBetween={1}
                    slidesPerGroup={slidesPerView - slidesPerView % 1}

                    modules={[Autoplay]}
                >
                    {products && products.map((element, id) => {
                        let productCurrentState
                        if (state){
                            productCurrentState = state;
                        }else if(fromSimilar){
                            productCurrentState = 'raw';
                        }else if(fromRecently && fromRecently.length !== 0){
                            productCurrentState = fromRecently[id];
                        }
                        return(
                            <SwiperSlide  className={styles.swiper_slide} key= {id}>
                                <div className={styles.sliderDiv}>
                                    <ProductCart
                                        productImages={productCurrentState === 'cooked' ? element.productImages.productSmallCookedImage : element.productImages.productSmallRawImage}
                                        productId={element._id}
                                        productTitle={element.productTitle}
                                        type={element.type}
                                        userCartProducts={cartProducts}
                                        userFavoriteProducts={favorites}
                                        loading= {loading}
                                        defaultPrice={Number(element.price)}
                                        preparationPrice={fromSimilar ? 0 : Number(element.preparationPrice)}
                                        state={productCurrentState}
                                    />
                                </div>
                            </SwiperSlide>
                        )})
                    }
                </Swiper>
                <div className={styles.swiper_btn_div}>

                    <button className={isMobile ? styles.display_none : styles.swiper_nav_btn} onClick={()=>{
                        swiper.slideNext();
                    }}>
                        <img width="17" height="30"  src={nextIcon} alt="next" />
                    </button>
                </div>
            </div>
            {isMobile &&
                <div className={styles.swiper_nav_div}>
                    <button className={styles.swiper_nav_btn} onClick={() => {
                        swiper.slidePrev();
                    }}>
                        <img width="17" height="30"  src={prevIcon} alt="prev"/>
                    </button>
                    <button className={styles.swiper_nav_btn}
                            onClick={() => {
                                swiper.slideNext();
                            }}>
                        <img width="17" height="30"  src={nextIcon} alt="next"/>
                    </button>
                </div>
            }

        </div>
    );
};

export default ProductSlider;