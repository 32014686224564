import FavoriteSection from "../../component/organism/Favorite";

const Favorite = () => {

    return (
        <div>
           <FavoriteSection/>
        </div>
    );
};

export default Favorite;