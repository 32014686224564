import styles from './style.module.scss';

import forkBg from "../../../../assets/backgronds/catalog/forkBg.png";

import Toolbar from "../../../molecul/CatalogPage/Toolbar";
import {useEffect, useRef, useState} from "react";
import {gql, useQuery} from "@apollo/client";
import {useSelector} from "react-redux";
import ProductCart from "../../../molecul/ProductCart";
import {Skeleton} from "@mui/material";
import {useLocation} from "react-router-dom";
import MobileCatalogSideBar from "../../../molecul/CatalogPage/Sidebar/Mobile";
import SearchBar from "../../../molecul/Layout/SearchBar";

import en from "../../../../assets/lang/en/MenuPage/en.json";
import SpecialOffersMenuMobile from "../../../molecul/CatalogPage/SpecialOffersMenuMobile";
import am from "../../../../assets/lang/am/MenuPage/en.json";
import ru from "../../../../assets/lang/ru/MenuPage/en.json";
import PartChoosingSection from "../../../molecul/CatalogPage/PartChoosingSection/Mobile";
import SearchError from "../../../molecul/CatalogPage/SearchError";

const GET_CATALOG_ITEMS = gql`
  query filteredProducts($state: String, $specialOffers: Boolean, $categories: [String], $userId: ID!, $searchOptions: String) {
    filteredProducts(state: $state, specialOffers: $specialOffers, categories: $categories , searchOptions: $searchOptions){
        _id
      state
      productTitle{
        am
        ru
        en
      }
      type
      price
      preparationPrice
      productImages{
        productSmallRawImage
        productSmallCookedImage
      }
    }
    userFavoriteProducts(userId: $userId){
      _id
      productId
      state
    }
    userCartProducts(userId: $userId){
        _id
        price
        quantity
        productId
        productImages
        productTitle{
            en
            am
            ru
        }
        defaultPrice
        state
      }
  }
`;

const MobileCatalogSection = () => {
    const stateUser = useSelector(state => state.user.user);
    const user = stateUser ? stateUser._id : '';
    const toolBarRef = useRef(null);
    const [categoriesSidebarOpen, setCategoriesSidebarOpen] = useState(false);

    const {state} = useLocation();

    const lang = useSelector(state => state.globalLang.lang);


    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);

    const [filterValue, setFilterValue] = useState({
        state: state && state.productState ? state.productState : 'raw',
        specialOffers: state && state.specialOffers ? state.specialOffers : false,
        categories: state && state.subcategory ? state.subcategory : [],
        searchOptions: state && state.searchOptions ? state.searchOptions : '',
    });

    useEffect(() => {
        // Update the state based on the new location object
        state && state.searchOptions &&
        setTimeout(() => {
            window.scrollTo({left: 0, top: toolBarRef.current.offsetTop - 104, behavior: 'smooth'})
        }, 500);
        setFilterValue({
            state: state && state.productState ? state.productState : 'raw',
            specialOffers: state && state.specialOffers ? state.specialOffers : false,
            categories: state && state.subcategory ? state.subcategory : [],
            searchOptions: state && state.searchOptions ? state.searchOptions : '',
        });
    }, [state]);


    const {loading, error, data, refetch} = useQuery(GET_CATALOG_ITEMS, {
        variables: {
            ...filterValue,
            userId: user
        },
    });

    useEffect(() => {

        refetch({
            ...filterValue,
            userId: user
        },);
    }, [filterValue, user, refetch]);

    return (
        <div className={styles.catalog_section}>
            <div className={styles.centered_flex_div}>
                <img src={forkBg} alt={"fork"} className={styles.forkBg}/>
            </div>
            <div className={styles.section_heading_div}>
                <h1>{langObj.sectionHeading}</h1>
                <PartChoosingSection
                    activeIndex={state && state.activeIndex}
                    scrollPage={() => {
                        window.scrollTo({left: 0, top: toolBarRef.current.offsetTop - 104, behavior: 'smooth'});
                    }}
                    value={filterValue}
                    changeState={
                        (value) => {
                            setFilterValue(value);
                        }
                    }
                />
                <div className={styles.centered_flex_div}>
                    <SearchBar
                        navigateSearchOptions={state && state.searchOptions ? state.searchOptions : ''}
                        value={filterValue}
                        changeState={
                            (value) => {
                                setFilterValue(value);
                            }
                        }/>
                </div>
                <p>
                    {langObj.sectionParagraph}
                </p>
            </div>
            <div className={styles.main_catalog_div} ref={toolBarRef}>
                <Toolbar
                    lang={langObj}
                    value={filterValue}
                    changeState={
                        (value) => {
                            setFilterValue(value);
                        }
                    }
                />
                <SpecialOffersMenuMobile
                    lang={langObj}
                    value={filterValue}
                    openSideBar={() => {
                        setCategoriesSidebarOpen(true);
                    }}
                    changeState={
                        (value) => {
                            setFilterValue(value);
                        }
                    }
                />
                <div className={styles.product_div}>

                    <div className={styles.products_section}>
                        {loading &&
                            <div>
                                <Skeleton/>
                            </div>
                        }
                        {
                            error &&
                            <p>Error :(</p>
                        }
                        {!loading && data && data.filteredProducts.length === 0
                            ?
                            <SearchError lang={langObj}/>
                            :
                            !loading && data && data.filteredProducts && data.filteredProducts.map((item, id) => {
                                return (
                                    <div key={id} style={{
                                        zIndex: 1
                                    }}>
                                        <ProductCart
                                            productId={item._id}
                                            productImages={filterValue.state === 'cooked' ? item.productImages.productSmallCookedImage : item.productImages.productSmallRawImage}
                                            productTitle={item.productTitle}
                                            type={item.type}
                                            userCartProducts={data && data.userCartProducts && data.userCartProducts}
                                            userFavoriteProducts={data && data.userFavoriteProducts && data.userFavoriteProducts}
                                            loading={loading}
                                            defaultPrice={Number(item.price)}
                                            preparationPrice={Number(item.preparationPrice)}
                                            state={filterValue.state}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <MobileCatalogSideBar
                lang={langObj}
                closeSidebar={() => {
                    setCategoriesSidebarOpen(false)
                }}
                isOpen={categoriesSidebarOpen}
                value={filterValue}
                changeState={
                    (value) => {
                        setFilterValue(value);
                    }
                }
            />
        </div>
    );
};

export default MobileCatalogSection;