import CartSection from "../../component/organism/Cart";


const Cart = () => {
    return (
        <div>
            <CartSection />
        </div>
    );
};

export default Cart;