import styles from "./style.module.scss";
import en from "../../../../../assets/lang/en/CartPage/en.json";
import ru from "../../../../../assets/lang/ru/CartPage/en.json";
import am from "../../../../../assets/lang/am/CartPage/en.json";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";


const CheckoutProductSectionDesktop = ({data}) => {
    const lang = useSelector(state => state.globalLang.lang);


    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);
    return (
        <div className={styles.cart_item_table_div}>
            <div className={styles.cart_item_heading_div}>
                <div className={styles.cart_item_heading + ' ' + styles.big_div}>
                    {langObj.productHeading}
                </div>
                <div className={styles.cart_item_heading + ' ' + styles.medium_div}>
                    {langObj.priceHeading}
                </div>
                <div className={styles.cart_item_heading + ' ' + styles.medium_div}>
                    {langObj.quantityHeading}
                </div>
                <div className={styles.cart_item_heading + ' ' + styles.medium_div}>
                    {langObj.totalPriceHeading}
                </div>

            </div>
            <div className={styles.cart_items_table}>
                {data && data.map((item, id) => {
                    return (
                        <div
                            className={styles.cart_item_div}
                            key={id}>
                            <div className={styles.cart_item + ' ' + styles.big_div}>
                                <div className={styles.product_image_name}>
                                    <img src={item.productImages} alt="productImg" className={styles.product_images}/>
                                    <span>{lang === "ENG" ? item.productTitle.en : lang === "RU" ? item.productTitle.ru : item.productTitle.am}</span>
                                </div>
                            </div>
                            <div className={styles.cart_item + ' ' + styles.medium_div}>
                                {item.defaultPrice } <span className={styles.symbol}> ֏ </span>
                            </div>
                            <div className={styles.cart_item + ' ' + styles.medium_div}>
                                <div className={styles.quantity_div}>
                                    {item.quantity + " " + langObj.kg}
                                </div>
                            </div>
                            <div className={styles.cart_item + ' ' + styles.medium_div}>
                                {item.price } <span className={styles.symbol}> ֏ </span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default CheckoutProductSectionDesktop;