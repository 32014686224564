import styles from "./style.module.scss"
import facebookIcon from '../../../../assets/icons/layout/footer/facebookIcon.png';
import instaIcon from '../../../../assets/icons/layout/footer/instaIcon.png';

import {Link} from "react-router-dom";
import siteLogo from "../../../../assets/logo/footerLogo.png";
import callIcon from "../../../../assets/icons/layout/navbar/Call.png";




const DesktopFooter = ({lang}) => {
    return (
        <div className={styles.footer}>
            <div className={styles.footer_first_section}>
                <div  className={styles.footer_logo_section}>
                    <img src={siteLogo} alt={"logo"}/>
                    <div className={styles.contact_section}>
                        <img src={callIcon} alt={"call"}/>
                        <p> {lang.tell} </p>
                    </div>
                </div>
                <div className={styles.footer_section}>
                    <Link to={'catalog'}>
                        {lang.menu}
                    </Link>
                    <Link to={'about_us'}>
                        {lang.about}
                    </Link>
                    <Link to={'contact_us'}>
                        {lang.contactUs}
                    </Link>
                    <a onClick={(event)=> {
                        event.stopPropagation();
                    }}>
                        {lang.blog}
                    </a>
                </div>
                <div className={styles.footer_section + ' ' + styles.footer_social_section}>
                    <img src={facebookIcon} alt={'facebook'}/>
                    <img src={instaIcon} alt={'instagram'}/>
                </div>
            </div>
            <div className={styles.footer_second_section}>
                © 2023 The Meat House | All rights reserved
            </div>
        </div>
    )
}

export default DesktopFooter;