import {useMutation, gql} from '@apollo/client';

import {useDispatch, useSelector} from "react-redux";

import {notifyUser} from "../../../redux/features/popup/notifyMessage";

const GET_CART_ITEMS = gql`
  query userCartProducts($userId: ID!) {
    userCartProducts(userId: $userId){
      _id
      price
      quantity
      productId
      productImages
      productTitle{
          en
          am
          ru
      }
      defaultPrice
      state
    }
  }
`;

const ADD_ITEM_TO_CART = gql`
    mutation Mutation(
        $addToCartInput: AddToCartInput
    ) {
        addToUserCart(
          addToCartInput: $addToCartInput
        ) {
          _id
          price
          quantity
          productId
          productImages
          productTitle{
              en
              am
              ru
          }
          defaultPrice
          state
        }
    }
`;


const useAddToCart = () => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const user = isAuthenticated ? stateUser.user : {};
    const dispatch = useDispatch();

    const [addItemToCart, {loading: addItemLoading, error: addItemError}] = useMutation(ADD_ITEM_TO_CART, {
            update: (cache, {data: {addToUserCart}}) => {
                const {userCartProducts} = cache.readQuery({
                    query: GET_CART_ITEMS,
                    variables: {userId: user._id},
                });
                cache.writeQuery({
                    query: GET_CART_ITEMS,
                    variables: {userId: user._id},
                    data: {userCartProducts: [...userCartProducts, addToUserCart]},
                });
            },
            onError({graphQLErrors}) {
                if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                    dispatch(notifyUser({
                        barOpen: true,
                        message: graphQLErrors[0].message,
                    }));
                }
            }
        },
    );

    return {addItemToCart, addItemLoading, addItemError};
}

export default useAddToCart;