import styles from './style.module.scss';

import {logoutSuccess} from '../../../../redux/features/user/userSlice';

import InputWIthSearch from '../../../../component/molecul/Layout/SearchBar';

import {useLocation, useNavigate} from 'react-router-dom';

import callIcon from "../../../../assets/icons/layout/navbar/Call.png";
import settingsIcon from "../../../../assets/icons/layout/navbar/Settings.webp";
import ordersIcon from "../../../../assets/icons/layout/navbar/Orders.webp";
import logoutIcon from "../../../../assets/icons/layout/navbar/Logout.webp";
import wishIcon from "../../../../assets/icons/layout/navbar/Heart.png";
import cartIcon from "../../../../assets/icons/layout/navbar/Cart.png";
import fillWishIcon from "../../../../assets/icons/layout/navbar/fillHeart.png";

import arrowUpIcon from "../../../../assets/icons/layout/navbar/ArrowUp.png";
import arrowDownIcon from "../../../../assets/icons/layout/navbar/ArrowDown.png";

import profileIcon from "../../../../assets/icons/layout/navbar/ProfileIcon.png";

import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useRef, useState} from 'react';
import LoginPopUp from '../../../../component/molecul/LoginPopup';
import {openPopup} from '../../../../redux/features/popup/openLoginPopupSlice';

import siteLogo from '../../../../assets/logo/logo.png';
import {changeLang} from "../../../../redux/features/lang/changeLangSlice";

const langArray = ["ENG", "ARM", "RU"];
const DesktopNavbar = ({isAuthenticated, data, loading, error, langPack}) => {
    const lang = useSelector(state => state.globalLang.lang);
    const direction = useLocation().pathname;
    const [chosenLang, setChosenLang] = useState(lang);
    const profileBtnRef = useRef(null);
    const langDropdownRef = useRef(null);

    const [dropdownPosition, setDropdownPosition] = useState(profileBtnRef.current && profileBtnRef.current.getBoundingClientRect().x - 53)

    const popupState = useSelector(state => state.isPopupOpen.isPopupOpen);
    const isLoginPopUpOpen = popupState.popupOpen;
    const [myProfileDropped, setMyProfileDropped] = useState(false);
    const [langDropDownIsOpen, setLangDropDownIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState("");

    const setClassHandler = (e)=>{
        if(window.scrollY >= 1){
            setIsScrolled("_scrolled");
        }
        else if (window.scrollY < 1){
            setIsScrolled("");
        }
    };

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const dropdownRef = useRef(null);
    const langBtnRef = useRef(null);


    useEffect(() => {
        window.addEventListener("scroll", setClassHandler);
        const handleClickOutside = (event) => {

            if (
                (!dropdownRef?.current?.contains(event.target) && !profileBtnRef?.current?.contains(event.target))
                && ((!langDropdownRef?.current?.contains(event.target) && !langBtnRef?.current?.contains(event.target)))
            ) {
                setMyProfileDropped(false);
                setLangDropDownIsOpen(false)
            }
        };


        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("scroll", setClassHandler);
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        dispatch(changeLang(chosenLang));
    },[chosenLang]);
    const handleResize = () => {
        const profileBtnPosition = profileBtnRef.current && profileBtnRef.current.getBoundingClientRect();

        setDropdownPosition(profileBtnPosition && profileBtnPosition.x);

        setMyProfileDropped(false);
    }
    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, []);

    return (
        <div className={styles["without_image_navbar" + isScrolled]}>
            <div className={styles["navbar_section" + isScrolled]}>
                <div className={styles.nav_section_first_div}>
                    <img src={siteLogo} alt={"logo"} className={styles["navbar_logo" + isScrolled]} onClick={() => {
                        if(direction === "/") {
                            window.scrollTo(0,0);
                        }else{
                            navigate('/');
                        }
                    }}/>
                    <div className={styles["contact_section" + isScrolled]}>
                        <img src={callIcon} alt={"call"}/>
                        <p> {langPack.tell} </p>
                    </div>
                </div>
                <div className={styles.nav_section_second_div}>
                    <button className={styles.second_section_second_btn} onClick={() => {
                        if (isAuthenticated) {
                            navigate('/Wishlist');
                        } else {
                            dispatch(openPopup({
                                popupOpen: true,
                                fromNavbar: '/Wishlist'
                            }));
                        }
                    }}>
                        <div className={styles.for_count_icon_flex_div}>
                            <div className={styles.btn_icon_div}>
                                {
                                    direction === '/Wishlist' ?
                                        <img src={fillWishIcon} alt={"wishlist"}/>
                                        :
                                        <img src={wishIcon} alt={"wishlist"}/>
                                }

                            </div>
                            {isAuthenticated && !loading && data && data.userFavoriteProducts && data.userFavoriteProducts.length !== 0 &&
                                <div className={styles.item_quantity}> {data.userFavoriteProducts.length} </div>
                            }
                        </div>
                    </button>
                    <button className={styles.second_section_second_btn} onClick={() => {
                        if (isAuthenticated) {
                            navigate('/Cart');
                        } else {

                            dispatch(openPopup({
                                popupOpen: true,
                                fromNavbar: '/Cart'
                            }));
                        }
                    }}>
                        <div className={styles.for_count_icon_flex_div}>
                            <div className={styles.btn_icon_div}>
                                <img src={cartIcon} alt={"cart"}/>
                            </div>
                            {isAuthenticated && !error && !loading && data && data.userCartProducts && data.userCartProducts.length !== 0 &&
                                <div className={styles.item_quantity}>
                                    {data.userCartProducts.length}
                                </div>
                            }
                        </div>
                    </button>
                    {direction !== '/catalog' && <InputWIthSearch/>}
                    {!isAuthenticated ?
                        <button className={styles.login_btn} onClick={() => {
                            navigate('/sign_in');
                        }}>
                            <span> {langPack.signInBtn} </span>
                        </button>
                        :
                        <div className={styles.my_profile_div} ref={profileBtnRef}>
                            <button className={isAuthenticated ? styles.logged_btn : styles.login_btn} onClick={() => {
                                setMyProfileDropped(!myProfileDropped);
                            }}>
                                <img src={profileIcon} alt={'profile'}/>
                            </button>
                            {myProfileDropped &&
                                <div
                                    className={styles.dropdown_menu}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}
                                    ref={dropdownRef}
                                    style={{
                                        left: dropdownPosition ? dropdownPosition - 53 : profileBtnRef.current.getBoundingClientRect().x - 53,
                                    }}
                                >
                                    <button className={styles.logOutBtn} onClick={() => {
                                        navigate('/my_orders');
                                        setMyProfileDropped(!myProfileDropped)
                                    }}>
                                        <img src={ordersIcon} alt={"ordersIcon"}/>
                                        <span>{langPack.orders}</span>
                                    </button>
                                    <button className={styles.logOutBtn} onClick={() => {
                                        navigate('/profile_settings');
                                        setMyProfileDropped(!myProfileDropped)
                                    }}>
                                        <img src={settingsIcon} alt={"settingIcon"}/>
                                        <span>{langPack.settings}</span>
                                    </button>
                                    <button className={styles.logOutBtn} onClick={() => {
                                        dispatch(logoutSuccess());
                                        setMyProfileDropped(!myProfileDropped);
                                    }}>
                                        <img src={logoutIcon} alt={"logOutIcon"}/>
                                        <span>{langPack.logOut}</span>
                                    </button>
                                </div>
                            }

                        </div>
                    }
                    <div>
                        <button className={styles.lang_btn} ref={langBtnRef} onClick={() => {
                            setLangDropDownIsOpen(!langDropDownIsOpen);
                        }}>
                            <span>{chosenLang}</span>
                            {
                                langDropDownIsOpen ?
                                    <img src={arrowUpIcon} alt={'open'}/>
                                    :
                                    <img src={arrowDownIcon} alt={'close'}/>
                            }
                        </button>
                        {langDropDownIsOpen &&
                            <div
                                className={styles.lang_dropdown_menu}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                                ref={langDropdownRef}
                            >
                                {
                                    langArray.map((item, id) => {
                                            if (item !== chosenLang) {
                                                return (
                                                    <button key={id} onClick={() => {
                                                        setChosenLang(item);
                                                        setLangDropDownIsOpen(false)
                                                    }}>
                                                        {item}
                                                    </button>
                                                )
                                            }
                                        }
                                    )
                                }

                            </div>
                        }
                    </div>
                </div>
            </div>
            {isLoginPopUpOpen && <LoginPopUp/>}
        </div>
    );
};

export default DesktopNavbar;