import styles from './style.module.scss';
import priceBg from '../../../assets/backgronds/orderDone/priceBg.png';

import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLazyQuery, gql, useMutation} from '@apollo/client';
import en from "../../../assets/lang/en/OrderDone/en.json";
import am from "../../../assets/lang/am/OrderDone/en.json";
import ru from "../../../assets/lang/ru/OrderDone/en.json";
import {notifyUser} from "../../../redux/features/popup/notifyMessage";
import RejectedSection from "../../molecul/RejectedSection";
import useRemoveCartItem from "../../../hooks/product/cart/useRemoveCartItem";


const GET_ORDER_DETAILS = gql`
  query OrderDetails($transactionId: Int!) {
    orderDetails(transactionId: $transactionId){
        totalPrice
        orderNumber
        deliveryTime
        date
    }
  }
`;
const VERIFY_PAYMENT_DETAILS = gql`
  mutation VerifyingPayment($paymentDetailsInput: PaymentDetailsInput){
    paymentVerifying(paymentDetailsInput: $paymentDetailsInput){
        totalPrice
        orderNumber
        deliveryTime
        date
        cartProductsIds
    }
  }
`;
const OrderDoneSection = () => {
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    const navigate = useNavigate();
    const {state} = useLocation();
    const {removeCartItem} = useRemoveCartItem();

    const lang = useSelector(state => state.globalLang.lang);
    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);

    const location = useLocation();
    const slashIndex = location.pathname.lastIndexOf("/")
    const pathname = location.pathname.slice(slashIndex + 1, location.pathname.length);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const navigateData = state && state.navigateData;



    const [orderData, setOrderData] = useState({});


    const [getOrderDetails, {data: queryData, error, loading: queryLoading}] = useLazyQuery(GET_ORDER_DETAILS);
    const [verifyPayment, {
        data: verifiedData,
        error: verifiedError,
        loading: verifying
    }] = useMutation(VERIFY_PAYMENT_DETAILS, {
        errorPolicy: 'all',
        update(proxy, { data: { paymentVerifying: verifiedData }}) {
            setOrderData(verifiedData);
            verifiedData.cartProductsIds.map(async (cartItem) => {
                await removeCartItem({
                    variables: {
                        removeCartItemInput: {
                            cartItemId: cartItem,
                        },
                    },
                });
            });
        },
        onError({ graphQLErrors }) {
            if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                const message = JSON.parse(graphQLErrors[0].message);
                dispatch(notifyUser({
                    barOpen: true,
                    message: lang === "ARM" ? message.am : lang === "RU" ? message.ru : message.en,
                }));
            }
        },
    });

    const asyncVerifyPayment = async (paymentId, orderId) => {
        await verifyPayment({
            variables: {
                paymentDetailsInput: {
                    paymentID: paymentId,
                    orderID: orderId,
                }
            }
        });
    };

    useEffect(() => {
        const paymentID = searchParams.get("paymentID");
        const orderID = searchParams.get("orderID");
        const transactionId = searchParams.get("order");
        window.scrollTo(0, 0);
        if (!navigateData && pathname !== "ameriabank" && !paymentID) {
            getOrderDetails({
                variables: {
                    transactionId: Number(transactionId)
                }
            });
        } else if (!navigateData && pathname === "ameriabank" && paymentID && !verifying) {
            asyncVerifyPayment(paymentID,orderID);
        } else {
            setOrderData(navigateData);
        }
        if (((!state || !state.navigateData) && !transactionId) && pathname !== "ameriabank") {
            navigate("/");
        }
    }, []);
    useEffect(() => {
        if (!queryLoading && !error && queryData && queryData.orderDetails) {
            setOrderData(queryData.orderDetails);
        }
    }, [queryData, queryLoading, error])

    if (queryLoading || verifying) {
        return (
            <div>
                ...loading
            </div>
        );
    } else if (error) {
        return (
            <div>
                error :(
            </div>
        );
    } else if (verifiedError) {
        return (
            <>
               <RejectedSection />
            </>
        );
    }

    if (isMobile) {
        return (
            <div className={styles.main_section_mobile}>
                <div className={styles.section_heading_mobile}>
                    <div>
                        <hr/>
                        <h1>
                            {langObj.sectionHeading}
                        </h1>
                        <hr/>
                    </div>
                </div>
                <div className={styles.order_details_mobile}>
                    <div className={styles.order_details_heading_mobile}>
                    <span>
                        {langObj.orderStatus}
                    </span>
                        {langObj.status}
                    </div>
                    <div className={styles.input_section_mobile}>
                        <div className={styles.input_div_mobile}>
                            <label>{langObj.orderNumber}</label>
                            <input type={"text"} value={orderData.orderNumber} readOnly={true}/>
                        </div>
                        <div className={styles.input_div_mobile}>
                            <label>{langObj.deliveryDate}</label>
                            <input type={"text"} value={orderData.deliveryTime} readOnly={true}/>
                        </div>
                    </div>
                    <div className={styles.input_section_mobile}>
                        <div className={styles.input_div_mobile}>
                            <label>{langObj.orderDate}</label>
                            <input type={"text"} value={orderData.date} readOnly={true}/>
                        </div>
                        <div className={styles.input_div_mobile}>
                            <label>{langObj.deliveryAmount}</label>
                            <input type={"text"} value={orderData.totalPrice >= 30000 ? langObj.free : 1000 + " ֏"} readOnly={true}/>
                        </div>
                    </div>
                    <div className={styles.total_price_div_mobile}>
                    <span>
                        {langObj.totalAmount}
                    </span>
                        <div className={styles.total_price_mobile}>
                            <span>{orderData.totalPrice + " ֏"}</span>
                            <img src={priceBg} alt={'priceBg'}/>
                        </div>
                    </div>
                </div>
                <p className={styles.section_paragraph_mobile}>
                    {langObj.sectionParagraph}
                </p>

                <button className={styles.backBtn_mobile} onClick={() => {
                    navigate('/');
                }}>
                    {langObj.backBtn}
                </button>
            </div>
        );
    }

    return (
        <div className={styles.main_section}>
            <div className={styles.section_heading}>
                <div>
                    <hr/>
                    <h1>
                        {langObj.sectionHeading}
                    </h1>
                </div>
            </div>
            <div className={styles.order_details}>
                <div className={styles.order_details_heading}>
                    <span>
                        {langObj.orderStatus}
                    </span>
                    {langObj.status}
                </div>
                <div className={styles.input_section}>
                    <div className={styles.input_div}>
                        <label>{langObj.orderNumber}</label>
                        <input type={"text"} value={orderData.orderNumber} readOnly={true}/>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.deliveryDate}</label>
                        <input type={"text"} value={orderData.deliveryTime} readOnly={true}/>
                    </div>
                </div>
                <div className={styles.input_section}>
                    <div className={styles.input_div}>
                        <label>{langObj.orderDate}</label>
                        <input type={"text"} value={orderData.date} readOnly={true}/>
                    </div>
                    <div className={styles.input_div}>
                        <label>{langObj.deliveryAmount}</label>
                        <input type={"text"} value={orderData.totalPrice >= 30000 ? langObj.free : 1000 + " ֏"} readOnly={true}/>
                    </div>
                </div>
                <div className={styles.total_price_div}>
                    <span>
                        {langObj.totalAmount}
                    </span>
                    <div className={styles.total_price}>
                        <span>{orderData.totalPrice + " ֏"}</span>
                        <img src={priceBg} alt={'priceBg'}/>
                    </div>
                </div>
            </div>
            <p className={styles.section_paragraph}>
                {langObj.sectionParagraph}
            </p>

            <button className={styles.backBtn} onClick={() => {
                navigate('/');
            }}>
                {langObj.backBtn}
            </button>
        </div>
    );
};

export default OrderDoneSection;