import {useMutation, gql} from '@apollo/client';

import {useDispatch, useSelector} from "react-redux";
import {notifyUser} from "../../../redux/features/popup/notifyMessage";


const GET_CART_ITEMS = gql`
  query userCartProducts($userId: ID!) {
    userCartProducts(userId: $userId){
        _id
        price
        quantity
        productId
        productImages
        productTitle{
            en
            am
            ru
        }
        defaultPrice
        state
    }
  }
`;

const UPDATE_CART_ITEM = gql`
    mutation Mutation(
        $updateCartInput: UpdateCartInput
    ) {
        updateCartItem(
            updateCartInput: $updateCartInput
        ) {
            _id
            price
            quantity
            productId
            productImages
            productTitle{
                en
                am
                ru
            }
            defaultPrice
            state
        }
    }
`;

const useUpdateCartItem = (state,) => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const user = isAuthenticated ? stateUser.user : {};

    const dispatch = useDispatch();
    const [updateCartItem, {loading: updateCartLoading, error: updateCartError}] = useMutation(UPDATE_CART_ITEM, {
            update: (cache, {data: {updateCartItem}}) => {
                const {userCartProducts} = cache.readQuery({
                    query: GET_CART_ITEMS,
                    variables: {
                        userId: user._id
                    },
                });

                const index = userCartProducts.findIndex(x => x._id === updateCartItem._id);
                const newArray = [...userCartProducts];
                newArray[index] = updateCartItem;

                cache.writeQuery({
                    query: GET_CART_ITEMS,
                    variables: {userId: user._id},
                    data: {userCartProducts: newArray},
                });
            },
            onError({graphQLErrors}) {
                if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                    dispatch(notifyUser({
                        barOpen: true,
                        message: graphQLErrors[0].message,
                    }));
                }
            }
        },
    );
    return {
        updateCartItem,
        updateCartError,
        updateCartLoading
    }
}

export default useUpdateCartItem;