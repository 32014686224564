const VerificationSection = ({changeSection}) => {
    return(
        <div>
            <button onClick={() => {changeSection(4) }}>
                click
            </button>
        </div>
    );
};

export default VerificationSection;