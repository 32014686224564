import {useMutation, gql} from '@apollo/client';

import {notifyUser} from "../../../redux/features/popup/notifyMessage";
import {useDispatch, useSelector} from "react-redux";

const GET_CART_ITEMS = gql`
  query userCartProducts($userId: ID!) {
    userCartProducts(userId: $userId){
      _id
      price
      quantity
      productId
      productImages
      productTitle{
          en
          am
          ru
      }
      defaultPrice
      state
      }
  }
`;

const REMOVE_CART_ITEM = gql`
    mutation Mutation(
        $removeCartItemInput: RemoveCartItemInput
    ) {
        removeCartItem(
            removeCartItemInput: $removeCartItemInput
        ) {
               _id
        }
    }
`;


const useRemoveCartItem = () => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const user = isAuthenticated ? stateUser.user : {};

    const dispatch = useDispatch();

    const [removeCartItem, {loading: removeItemLoading, error: removeItemError}] = useMutation(REMOVE_CART_ITEM, {
            update: (cache, {data: {removeCartItem}}) => {

                const {userCartProducts} = cache.readQuery({
                    query: GET_CART_ITEMS,
                    variables: {userId: user._id},
                });
                const updatedCartItems = userCartProducts.filter((item) => item._id !== removeCartItem._id);

                cache.writeQuery({
                    query: GET_CART_ITEMS,
                    variables: {userId: user._id},
                    data: {userCartProducts: updatedCartItems},
                });
            },
            onError({graphQLErrors}) {
                if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
                    dispatch(notifyUser({
                        barOpen: true,
                        message: graphQLErrors[0].message,
                    }));
                }
            }
        },
    );

    return {removeCartItem, removeItemLoading, removeItemError};
}

export default useRemoveCartItem;