import styles from './style.module.scss';

import reviewPlate from "../../../../../assets/backgronds/home/reviewSection/plateImg.webp"

import {useState} from "react";
import {useSelector} from "react-redux";
import nextIcon from "../../../../../assets/icons/homePage/commonIcons/nextIcon.png";
import prevIcon from "../../../../../assets/icons/homePage/commonIcons/prevIcon.png";

import commentIcon from "../../../../../assets/icons/homePage/commonIcons/commentIcon.png"

import {Rating} from "@mui/material";
import ReviewInputField from "../../../../atom/ProductReviewInputField";
import {gql, useQuery} from "@apollo/client";
import Skeleton from "@mui/material/Skeleton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {makeStyles} from "@mui/styles";
import {A11y} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


const GET_REVIEWS = gql`
  query($limit: Int, $skip: Int) {
    storeReviews(limit: $limit, skip: $skip){
      _id
      userName
      rating
      date
      comment
      collectionLength
    }
  }
`;

const useStyles = makeStyles((theme) => ({

    emptyStar: {
        color: "#730101"
    }
}));


const ReviewsSectionMobile = ({lang}) => {
    const classes = useStyles();


    const [swiper, setSwiper] = useState();


    const isMobile = useSelector(state => state.screenVersion.isMobile);

    const {loading, error, data, refetch} = useQuery(GET_REVIEWS);


    if (error) {
        return (
            <div>
                error :(
            </div>
        );
    }
    return (
        <div className={styles.main_section}>
            <div className={styles.section_heading}>
                <div>
                    <hr/>
                    <h2>
                        {lang.sectionHeading}
                    </h2>
                </div>
            </div>
            <div className={styles.review_centered_section}>

                <h2 className={styles.section_question}>
                    {lang.sectionQuestion}
                </h2>
                <div className={styles.heading_btn_div}>
                    <button className={styles.swiper_nav_btn}
                            onClick={() => {
                                swiper.slidePrev();
                            }}>
                        <img width="17" height="30"  src={prevIcon} alt="prev"/>
                    </button>
                    <Swiper
                        onSwiper={(swiper) => {
                            setSwiper(swiper);
                        }}
                        threshold={50}
                        modules={[A11y]}
                        spaceBetween={20}
                        slidesPerView={1}
                        slidesPerGroup={1}

                        className={styles.swiper}
                    >
                        {!loading && !error
                            ?
                            data && data.storeReviews && data.storeReviews.map((element, id) => {
                                return (
                                    <SwiperSlide key={id}>
                                        <div className={styles.sliderDiv}>
                                            <img width="53" height="40"  src={commentIcon} alt="icon"/>
                                            <p>{element.comment}</p>
                                            <Rating
                                                name="rating"
                                                value={element.rating}
                                                readOnly={true}
                                                sx={{
                                                    color: '#730101',
                                                    borderColor: '#730101'
                                                }}
                                                emptyIcon={
                                                    <StarBorderIcon fontSize="inherit"
                                                                    className={classes.emptyStar}/>
                                                }
                                            />
                                            <span className={styles.username}>{element.userName}</span>
                                            <span className={styles.date}>{element.date}</span>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                            :
                            <SwiperSlide>
                                <div className={styles.sliderDiv}>
                                    <img width="53" height="40" src={commentIcon} alt="icon"/>
                                    <p><Skeleton/></p>
                                    <Rating
                                        name="rating"
                                        value={0}
                                        readOnly={true}
                                        sx={{
                                            color: '#730101',
                                            borderColor: '#730101'
                                        }}
                                        emptyIcon={
                                            <StarBorderIcon fontSize="inherit"
                                                            className={classes.emptyStar}/>
                                        }
                                    />
                                    <span> <Skeleton/></span>
                                </div>
                            </SwiperSlide>
                        }
                    </Swiper>
                    <div className={styles.swiper_btn_div}>
                        <button className={styles.swiper_nav_btn} onClick={() => {
                            swiper.slideNext();
                        }}>
                            <img width="17" height="30" src={nextIcon} alt="next"/>
                        </button>
                    </div>
                </div>
                <div className={styles.comment_input}>
                    <img width="106" height="123.56" src={reviewPlate} alt={'plate'} className={isMobile && styles.plate_img}/>
                    <h3 className={styles.comment_input_heading}>
                        {lang.inputLabel}
                    </h3>
                    <ReviewInputField
                        lang={lang}
                        refetch={async () => {
                            await refetch();
                            swiper.slideTo(0);

                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReviewsSectionMobile;