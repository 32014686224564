import ContactUsSection from "../../component/organism/ContactUs";

const ContactUs = () => {

    return (
        <div>
            <ContactUsSection />
        </div>
    );
};

export default ContactUs;