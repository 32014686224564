import styles from './style.module.scss';

import en from '../../../../assets/lang/en/ProductPage/en.json';
import am from '../../../../assets/lang/am/ProductPage/en.json';
import ru from '../../../../assets/lang/ru/ProductPage/en.json';

import {useState, useEffect} from 'react';

import {useQuery, gql} from '@apollo/client';

import Skeleton from '@mui/material/Skeleton';

import {useDispatch, useSelector} from 'react-redux';

import {useParams, useLocation, Link, useNavigate} from 'react-router-dom';

import ProductPagePriceCalculator from '../../../atom/ProductPagePriceCalculator';
import SimilarProductSection from '../../../molecul/ProductPage/SimilarProducts';
import RecentlyViewed from '../../../molecul/ProductPage/RecentlyViewedProducts';
import {openPopup} from '../../../../redux/features/popup/openLoginPopupSlice';
import filledCookedFavoriteIcon from "../../../../assets/icons/homePage/commonIcons/filledCookedFavoriteIcon.png";
import cookedFavoriteIcon from "../../../../assets/icons/homePage/commonIcons/cookedFavoriteIcon.png";

import filledFavoriteIconMobile from "../../../../assets/icons/ProductPage/CommonIcons/filledFavoriteIconMobile.png";
import favoriteIconMobile from "../../../../assets/icons/ProductPage/CommonIcons/favoriteIconMobile.png";

import backIcon from "../../../../assets/icons/ProductPage/CommonIcons/navigateIcon.png";

import useAddFavoriteItem from "../../../../hooks/product/favorite/useAddFavoriteItem";
import useRemoveFavoriteItem from "../../../../hooks/product/favorite/useRemoveFavoriteItem";



const GET_ITEMS_FOR_PREVIEW = gql`
  query productWithId($userId: ID!, $_id: ID!) {
    productWithId( _id: $_id ){
        _id
         productTitle{
            en
            am
            ru
        }
        description{
            en
            am
            ru
        }
        preparationPrice
        price
        type
        productImages{
            productRawImage
            productCookedImage
        }
        state
    }
      userFavoriteProducts(userId: $userId){
      _id
      productId
      state
    }
  }
`;

const ProductPreviewSection = () => {
    const {pathname} = useLocation();

    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const stateUser = useSelector((state) => state.user);
    const userId = isAuthenticated ? stateUser.user._id : '';
    const {state} = useLocation();
    const productState =  state && state.productState ? state.productState : 'raw';

    const navigate = useNavigate();

    const [favoriteIdArray, setFavoriteIdArray] = useState([]);
    const [favoriteItemId, setFavoriteItemId] = useState('');

    const isMobile = useSelector(state => state.screenVersion.isMobile);
    const lang = useSelector(state => state.globalLang.lang);
    const dispatch = useDispatch();

    const productId = useParams().productId;
    const {loading, error, data} = useQuery(GET_ITEMS_FOR_PREVIEW, {
        variables: {
            userId: userId,
            _id: productId,
        },
    });

    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname, productState]);


    useEffect(() => {
        if (!loading && data && data.userFavoriteProducts) {
            const favoriteElements = data.userFavoriteProducts ? data.userFavoriteProducts : [];
            const favoriteItem = favoriteElements.find(item => item.productId === productId && item.state === productState);
            setFavoriteItemId(favoriteItem && favoriteItem._id);
            const favoriteEl = favoriteElements.map(obj => [obj.productId, obj.state]);
            setFavoriteIdArray(favoriteEl);
        }
    }, [data, productId, loading]);

    const {addItemToFavorite, addFavoriteError, addFavoriteLoading } = useAddFavoriteItem();
    const {removeFavoriteItem, removeFavoriteError, removeFavoriteLoading} = useRemoveFavoriteItem();


    if (loading) return (
        <div>
            <Skeleton/>
        </div>
    );
    if (error) return <p>Error :(</p>;

    const findArrayInArray = (array, includedArray) => {
        const searchArr = array.map(JSON.stringify);
        const arr = JSON.stringify(includedArray);
        const ifIncludes = searchArr.includes(arr);
        return ifIncludes;
    };

    if (isMobile) {
        return (
            <div className={styles.main_section_div_mobile}>
                <div className={styles.nav_div}>
                    <button className={styles.navigate_btn} onClick={() => {
                        navigate(-1)
                    }}>
                        <img src={backIcon} alt={"back"}/>
                        <span>{langObj.backBtn}</span>
                    </button>
                </div>
                <div className={styles.product_preview_div_mobile}>
                    <div className={styles.second_section_div_mobile}>
                        <div className={styles.heading_section_mobile}>
                                <div className={styles.product_title_mobile}>
                                    {lang === "ENG" ? data.productWithId.productTitle.en : lang === "RU" ? data.productWithId.productTitle.ru : data.productWithId.productTitle.am}
                                </div>

                                {findArrayInArray(favoriteIdArray, [productId, productState]) ?
                                    <button
                                        className={styles.favorite_btn_mobile}
                                        onClick={async () => {
                                            if (isAuthenticated && !addFavoriteLoading && !addFavoriteError && !removeFavoriteLoading && !removeFavoriteError) {
                                                await removeFavoriteItem({
                                                    variables: {
                                                        removeFromFavoriteInput: {
                                                            favoriteItemId: favoriteItemId,
                                                        },
                                                    },
                                                });
                                            }
                                        }}
                                    >
                                        <img src={filledFavoriteIconMobile} alt={"favorite"}/>
                                    </button>
                                    :
                                    <button
                                        className={styles.favorite_btn_mobile}
                                        onClick={async () => {
                                            if (isAuthenticated) {
                                                if(!addFavoriteLoading && !addFavoriteError && !removeFavoriteLoading && !removeFavoriteError){
                                                    await addItemToFavorite({
                                                        variables: {
                                                            addToUserFavoriteInput: {
                                                                userId: userId,
                                                                productId: productId,
                                                                state: productState,
                                                            },
                                                        },
                                                    });
                                                }
                                            } else {
                                                dispatch(openPopup({
                                                    popupOpen: true,
                                                    fromNavbar: ''
                                                }));
                                            }
                                        }}
                                    >
                                        <img src={favoriteIconMobile} alt={"favorite"}/>

                                    </button>
                                }
                            </div>

                        <ProductPagePriceCalculator
                            state={productState}
                            description={lang === "ENG" ? data.productWithId.description.en : lang === "RU" ? data.productWithId.description.ru : data.productWithId.description.am}
                            defaultPrice={productState === 'raw' ? Number(data.productWithId.price) : Number(data.productWithId.price) + Number(data.productWithId.preparationPrice)}
                            id={data.productWithId._id}
                            loading={loading}
                            productTitle={data.productWithId.productTitle}
                            productImages={productState === 'raw' ?
                                data.productWithId.productImages.productRawImage
                                :
                                data.productWithId.productImages.productCookedImage
                            }
                            openPopup={() => {
                                dispatch(openPopup({
                                    popupOpen: true,
                                    fromNavbar: ''
                                }));
                            }}
                        />


                    </div>
                </div>
                <SimilarProductSection type={data.productWithId.type} currentItemId={data.productWithId._id} lang={langObj}/>
                <RecentlyViewed currentItemId={data.productWithId._id}  currentProductState={productState} lang={langObj}/>
            </div>
        )
    }
    return (
        <div className={styles.main_section_div}>
            <div className={styles.nav_div}>
                <button className={styles.navigate_btn} onClick={() => {
                    navigate(-1)
                }}>
                    <img src={backIcon} alt={"back"}/>
                    <span>{langObj.backBtn}</span>
                </button>
            </div>
            <div className={styles.product_preview_div}>
                <div className={styles.img_section_div}>
                    <img
                        src={productState === 'raw' ?
                            data.productWithId.productImages.productRawImage
                            :
                            data.productWithId.productImages.productCookedImage
                        }
                        alt={"productImage"}
                    />
                </div>
                <div className={styles.second_section_div}>
                    <div className={styles.heading_section}>
                            <div className={styles.product_title}>
                               {lang === "ENG" ? data.productWithId.productTitle.en : lang === "RU" ? data.productWithId.productTitle.ru : data.productWithId.productTitle.am}
                            </div>

                            {findArrayInArray(favoriteIdArray, [productId, productState]) ?
                                <button
                                    className={styles.favorite_btn}
                                    onClick={async () => {
                                        if (isAuthenticated && !addFavoriteLoading && !addFavoriteError && !removeFavoriteLoading && !removeFavoriteError) {
                                            await removeFavoriteItem({
                                                variables: {
                                                    removeFromFavoriteInput: {
                                                        favoriteItemId: favoriteItemId,
                                                    },
                                                },
                                            });
                                        }
                                    }}
                                >
                                    <img src={filledCookedFavoriteIcon} alt={"favorite"}/>
                                </button>
                                :
                                <button
                                    className={styles.favorite_btn}
                                    onClick={async () => {
                                        if (isAuthenticated) {
                                            if(!addFavoriteLoading && !addFavoriteError && !removeFavoriteLoading && !removeFavoriteError){
                                                await addItemToFavorite({
                                                    variables: {
                                                        addToUserFavoriteInput: {
                                                            userId: userId,
                                                            productId: productId,
                                                            state: productState,
                                                        },
                                                    },
                                                });
                                            }
                                        } else {
                                            dispatch(openPopup({
                                                popupOpen: true,
                                                fromNavbar: ''
                                            }));
                                        }
                                    }}
                                >
                                    <img src={cookedFavoriteIcon} alt={"favorite"}/>

                                </button>
                            }
                        </div>


                    <ProductPagePriceCalculator
                        state={productState}
                        description={lang === "ENG" ? data.productWithId.description.en : lang === "RU" ? data.productWithId.description.ru : data.productWithId.description.am}
                        defaultPrice={productState === 'raw' ? Number(data.productWithId.price) : Number(data.productWithId.price) + Number(data.productWithId.preparationPrice)}
                        id={data.productWithId._id}
                        favoriteData={data.userFavoriteProducts}
                        loading={loading}
                        productTitle={data.productWithId.productTitle}
                        productImages={productState === 'raw' ?
                            data.productWithId.productImages.productRawImage
                            :
                            data.productWithId.productImages.productCookedImage
                        }
                        openPopup={() => {
                            dispatch(openPopup({
                                popupOpen: true,
                                fromNavbar: ''
                            }));
                        }}
                    />


                </div>
            </div>
            <SimilarProductSection type={data.productWithId.type} currentItemId={data.productWithId._id} lang={langObj}/>
            <RecentlyViewed currentItemId={data.productWithId._id} currentProductState={productState} lang={langObj}/>

        </div>
    );
};

export default ProductPreviewSection;