import styles from './style.module.scss';

import en from "../../../../assets/lang/en/MenuPage/en.json";
import am from "../../../../assets/lang/am/MenuPage/en.json";
import ru from "../../../../assets/lang/ru/MenuPage/en.json";

import {DesktopCatalogSideBar} from "../../../molecul/CatalogPage/Sidebar";
import Toolbar from "../../../molecul/CatalogPage/Toolbar";
import {useEffect, useRef, useState} from "react";
import {gql, useQuery} from "@apollo/client";
import {useSelector} from "react-redux";
import ProductCart from "../../../molecul/ProductCart";
import {Skeleton} from "@mui/material";
import {useLocation} from "react-router-dom";
import InputWIthSearch from "../../../molecul/Layout/SearchBar";
import forkDesktop from "../../../../assets/backgronds/catalog/forkBgDesktop.webp"
import PartChoosingSectionDesktop from "../../../molecul/CatalogPage/PartChoosingSection/Desktop";
import SearchError from "../../../molecul/CatalogPage/SearchError";


const GET_CATALOG_ITEMS = gql`
  query filteredProducts($state: String, $specialOffers: Boolean, $categories: [String], $userId: ID!, $searchOptions: String) {
    filteredProducts(state: $state, specialOffers: $specialOffers, categories: $categories , searchOptions: $searchOptions){
        _id
      state
      productTitle{
        en
        am
        ru
      }
      type
      price
      preparationPrice
      productImages{
        productSmallRawImage
        productSmallCookedImage
      }
    }
    userFavoriteProducts(userId: $userId){
      _id
      productId
      state
    }
    userCartProducts(userId: $userId){
        _id
        price
        quantity
        productId
        productImages
        productTitle{
            en
            am
            ru
        }
        defaultPrice
        state
      }
  }
`;


const DesktopCatalogSection = () => {
    const stateUser = useSelector(state => state.user.user);
    const user = stateUser ? stateUser._id : '';
    const toolBarRef = useRef(null);

    const {state} = useLocation();


    const [filterValue, setFilterValue] = useState({
        state: state && state.productState ? state.productState : 'raw',
        specialOffers: state && state.specialOffers ? state.specialOffers : false,
        categories: state && state.subcategory ? state.subcategory : [],
        searchOptions: state && state.searchOptions ? state.searchOptions : '',
    });

    const lang = useSelector(state => state.globalLang.lang);


    const [langObj, setLangObj] = useState(en);
    useEffect(() => {
        if (lang === "ARM") {
            setLangObj(am);
        } else if (lang === "ENG") {
            setLangObj(en);
        } else {
            setLangObj(ru);
        }
    }, [lang]);

    useEffect(() => {
        state && state.searchOptions &&
        setTimeout(() => {
            window.scrollTo({left: 0, top: toolBarRef.current.offsetTop - 104, behavior: 'smooth'})
        }, 500);

        // Update the state based on the new location object
        setFilterValue({
            state: state && state.productState ? state.productState : 'raw',
            specialOffers: state && state.specialOffers ? state.specialOffers : false,
            categories: state && state.subcategory ? state.subcategory : [],
            searchOptions: state && state.searchOptions ? state.searchOptions : '',
        });
    }, [state]);


    const {loading, error, data, refetch} = useQuery(GET_CATALOG_ITEMS, {
        variables: {
            ...filterValue,
            userId: user
        },
    });

    useEffect(() => {
        refetch({
            ...filterValue,
            userId: user
        },);
    }, [filterValue, user, refetch]);


    if (error) return <p>Error :(</p>;


    return (
        <div className={styles.catalog_section}>
            <div className={styles.section_heading_div}>
                <div className={styles.centered_flex_div}>
                    <img src={forkDesktop} alt={"fork"} className={styles.fork_desktop}/>
                </div>

                <h2>{langObj.sectionHeading}</h2>
                <PartChoosingSectionDesktop
                    activeIndex={state && state.activeIndex}
                    scrollPage={() => {
                        window.scrollTo({left: 0, top: toolBarRef.current.offsetTop - 104, behavior: 'smooth'});
                    }}
                    value={filterValue}
                    changeState={
                        (value) => {
                            setFilterValue(value);
                        }
                    }
                />
            </div>
            <div className={styles.tool_bar_div} ref={toolBarRef}>
                <Toolbar
                    lang={langObj}
                    value={filterValue}
                    changeState={
                        (value) => {
                            setFilterValue(value);
                        }
                    }
                />
            </div>
            <div className={styles.main_catalog_div}>
                <div className={styles.sidebar_div}>
                    <DesktopCatalogSideBar
                        lang={langObj}
                        value={filterValue}
                        changeState={
                            (value) => {
                                setFilterValue(value);
                            }
                        }
                    />
                </div>
                <div className={styles.product_div}>
                    <div className={styles.search_bar}>
                        <InputWIthSearch
                            navigateSearchOptions={state && state.searchOptions ? state.searchOptions : ''}
                            value={filterValue}
                            changeState={
                                (value) => {
                                    setFilterValue(value);
                                }
                            }/>
                    </div>
                    <div className={styles.products_section}>
                        {loading &&
                            <div>
                                <Skeleton/>
                            </div>
                        }
                        {!loading && data && data.filteredProducts.length === 0
                            ?
                            <SearchError lang={langObj}/>
                            :
                            !loading && data && data.filteredProducts && data.filteredProducts.map((item, id) => {
                                return (
                                    <div key={id} style={{
                                        zIndex: 1
                                    }}>
                                        <ProductCart
                                            productId={item._id}
                                            productImages={filterValue.state === 'cooked' ? item.productImages.productSmallCookedImage : item.productImages.productSmallRawImage}
                                            productTitle={item.productTitle}
                                            type={item.type}
                                            userCartProducts={data && data.userCartProducts && data.userCartProducts}
                                            userFavoriteProducts={data && data.userFavoriteProducts && data.userFavoriteProducts}
                                            loading={loading}
                                            defaultPrice={Number(item.price)}
                                            preparationPrice={Number(item.preparationPrice)}
                                            state={filterValue.state}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DesktopCatalogSection;