import { useMutation, gql } from '@apollo/client';

import {useDispatch, useSelector} from "react-redux";
import {notifyUser} from "../../../redux/features/popup/notifyMessage";


const GET_FAVORITE_ITEMS = gql`
  query userFavoriteProducts($userId: ID!) {
    userFavoriteProducts(userId: $userId){
       _id
       productId
       state
    }
  }
`;
const REMOVE_FAVORITE_ITEM = gql`
    mutation Mutation(
        $removeFromFavoriteInput: RemoveFavoriteItemInput
    ) {
        removeFromFavorite(
            removeFromFavoriteInput: $removeFromFavoriteInput
        ) {
                  _id
        }
    }
`;


const useRemoveFavoriteItem = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const stateUser =  useSelector((state) => state.user);
  const user = isAuthenticated ? stateUser.user : {};
  const dispatch = useDispatch();

  const [removeFavoriteItem, {loading: removeFavoriteLoading, error: removeFavoriteError}] = useMutation(REMOVE_FAVORITE_ITEM,{
    update: (cache, { data: { removeFromFavorite } }) => {
        const { userFavoriteProducts } = cache.readQuery({
            query: GET_FAVORITE_ITEMS,
            variables: { userId: user._id },
          });

      const updatedFavoriteItems = userFavoriteProducts.filter((item) => item._id !== removeFromFavorite._id);
      cache.writeQuery({
        query: GET_FAVORITE_ITEMS,
        variables: { userId: user._id },
        data: { userFavoriteProducts: updatedFavoriteItems },
      });
    },
    onError({ graphQLErrors }) {
        if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
          dispatch(notifyUser({
            barOpen: true,
            message: graphQLErrors[0].message,
        }));
        }}},
    );

  return {removeFavoriteItem, removeFavoriteLoading, removeFavoriteError};
}

export default useRemoveFavoriteItem;