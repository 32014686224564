import styles from "../../style.module.scss"

import CustomLoginInput from "../../../../atom/CustomLoginInput";

import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notifyUser} from "../../../../../redux/features/popup/notifyMessage";
import backIcon from "../../../../../assets/icons/ProductPage/CommonIcons/navigateIcon.png";


const RegistrationSection = ({lang, changeSection, changeHandler, values, onSubmit}) => {
    const language = useSelector(state => state.globalLang.lang);
    const [confirmPassword, SetConfirmPassword] = useState('');
    const isMobile = useSelector(state => state.screenVersion.isMobile);
    const dispatch = useDispatch();
    const handleSubmit = () => {
        const containsFalsy = Object.values(values).some(item => !item || (Array.isArray(item) && item.length === 0));

        if (containsFalsy) {
            dispatch(notifyUser({
                barOpen: true,
                message: lang.emptyFieldWarning,
            }));
        } else {
            if (!isValidEmail(values.email)) {
                dispatch(notifyUser({
                    barOpen: true,
                    message: lang.wrongEmailWarning,
                }));
            } else if (values.tel.length !== 8) {
                dispatch(notifyUser({
                    barOpen: true,
                    message: lang.wrongTelWarning,
                }));
            } else {
                if (!confirmPassword && !values.password) {
                    dispatch(notifyUser({
                        barOpen: true,
                        message: lang.emptyFieldWarning,
                    }));
                } else {
                    if (confirmPassword === values.password) {
                        onSubmit();
                    } else {
                        dispatch(notifyUser({
                            barOpen: true,
                            message: lang.unlikePassword,
                        }));
                    }
                    ;
                }
                ;
            }
        }
    };


    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    if (isMobile) {
        return (
            <form className={styles.main_section_div_mobile} onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}>
                <div className={styles.login_section_mobile}>
                    <div className={styles.back_btn_div_mobile}>
                        <button
                            className={styles.back_btn + " " + styles.position_relative + " " + styles.no_margin}
                            onClick={() => {
                                changeSection(1);
                            }}
                        >
                            <img src={backIcon} alt={"icon"}/>
                            <span>{lang.backBtn}</span>
                        </button>
                    </div>
                    <h1 className={language === "RU" ? styles.heading_mobile_ru : styles.heading_mobile}>
                        {lang.signUpBtn}
                    </h1>
                    <div className={styles.inputdiv}>
                        <label htmlFor="name" className={styles.label_mobile}>
                            {lang.nameLabel}
                        </label>
                        <CustomLoginInput type="text" name="fullName"
                                          className={styles.loginInput + " " + styles.registration_input}
                                          onChange={changeHandler} customInputHeight={"50px"} maxLength={"30"}/>
                    </div>
                    <div className={styles.inputdiv + ' ' + styles.email_input}>
                        <label htmlFor="email" className={styles.label_mobile}>
                            {lang.emailLabel}
                        </label>
                        <CustomLoginInput type="email" name="email"
                                          className={styles.loginInput + " " + styles.registration_input}
                                          onChange={changeHandler} customInputHeight={"50px"}/>
                    </div>
                    <div className={styles.inputdiv + ' ' + styles.tel_input}>
                        <label htmlFor="tel" className={styles.label_mobile}>
                            {lang.phoneLabel}
                        </label>
                        <CustomLoginInput
                            type="number"
                            name="tel"
                            className={styles.loginInput + " " + styles.registration_input}
                            onChange={changeHandler}
                            customInputHeight={"50px"}
                            onKeyDown={(e) => {
                                const inputValue = e.target.value.toString();
                                if (e.key === '-' || e.key === '.' || e.key === '+') e.preventDefault();
                                else if (
                                    e.target.value.length > 7
                                    && e.key !== 'Backspace'
                                    && e.key !== 'Control'
                                    && e.key !== 'a'
                                    && e.key !== 'ArrowLeft'
                                    && e.key !== 'ArrowRight'
                                ) e.preventDefault();
                            }}
                        />
                    </div>
                    <div className={styles.inputdiv}>
                        <label htmlFor="name" className={styles.label_mobile}>
                            {lang.passwordLabel}
                        </label>
                        <CustomLoginInput type="password" name="password"
                                          className={styles.loginInput + " " + styles.registration_input}
                                          onChange={changeHandler} customInputHeight={"50px"} maxLength={"12"}/>
                    </div>
                    <div className={styles.inputdiv}>
                        <label htmlFor="pass" className={styles.label_mobile}>
                            {lang.confirmPassLabel}
                        </label>
                        <CustomLoginInput type="password" name="confirmPassword" customInputHeight={"50px"}
                                          className={styles.loginInput + " " + styles.registration_input}
                                          onChange={(event) => {
                                              SetConfirmPassword(event.target.value);
                                          }}/>
                    </div>
                    {
                        language === "RU"
                            ?
                            <button
                                className={styles.login_popup_btn_mobile}
                                onClick={() => {
                                    handleSubmit()
                                }}>
                                {lang.signUpBtn}
                            </button>
                            :
                            <button
                                className={styles.login_btn}
                            >
                                <div className={styles.plate_on_flour_mobile}>
                                    <div className={styles.flour_img_mobile}/>
                                    <div
                                        className={styles.plate_img_mobile}
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                    />
                                    <div className={styles.login_text_mobile} onClick={() => {
                                        handleSubmit()
                                    }}>
                                        {lang.signUpBtn}
                                    </div>
                                </div>
                            </button>
                    }
                    <div className={styles.register_btn_mobile}>
                    <span>
                       {lang.navigateSignIn.regular}
                    </span>
                        <button onClick={() => {
                            changeSection(1);
                        }}>
                            {lang.navigateSignIn.red}
                        </button>
                    </div>
                </div>
            </form>
        );
    }

    return (
        <form className={styles.main_section_div} onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
        }}
              onKeyDown={(e) => {
                  if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      handleSubmit();
                  }
              }}
        >
            <div className={styles.login_section}>
                <div className={styles.heading_div}>
                    <button className={styles.back_btn} onClick={() => {
                        changeSection(1);
                    }}>
                        <img src={backIcon} alt={"icon"}/>
                        <span>{lang.backBtn}</span>
                    </button>
                    <h1 className={styles.heading}>
                        {lang.signUpHeading}
                    </h1>
                </div>
                <div className={styles.inputdiv}>
                    <label htmlFor="name" className={styles.label}>
                        {lang.nameLabel}
                    </label>
                    <CustomLoginInput type="text" name="fullName"
                                      className={styles.loginInput + " " + styles.registration_input}
                                      onChange={changeHandler} customInputHeight={"50px"} maxLength={"30"}/>
                </div>
                <div className={styles.row_input_div}>
                    <div className={styles.inputdiv + ' ' + styles.email_input}>
                        <label htmlFor="email" className={styles.label}>
                            {lang.emailLabel}
                        </label>
                        <CustomLoginInput type="email" name="email"
                                          className={styles.loginInput + " " + styles.registration_input}
                                          onChange={changeHandler} customInputHeight={"50px"}/>
                    </div>
                    <div className={styles.inputdiv + ' ' + styles.tel_input}>
                        <label htmlFor="tel" className={styles.label}>
                            {lang.phoneLabel}
                        </label>
                        <CustomLoginInput
                            type="number"
                            name="tel"
                            className={styles.loginInput + " " + styles.registration_input}
                            onChange={changeHandler}
                            customInputHeight={"50px"}
                            onKeyDown={(e) => {
                                const inputValue = e.target.value.toString();
                                if (e.key === '-' || e.key === '.' || e.key === '+') e.preventDefault();
                                else if (
                                    e.target.value.length > 7
                                    && e.key !== 'Backspace'
                                    && e.key !== 'Control'
                                    && e.key !== 'a'
                                    && e.key !== 'ArrowLeft'
                                    && e.key !== 'ArrowRight'
                                ) e.preventDefault();
                            }}
                        />
                    </div>
                </div>
                <div className={styles.inputdiv}>
                    <label htmlFor="pass" className={styles.label}>
                        {lang.passwordLabel}
                    </label>
                    <CustomLoginInput type="password" name="password"
                                      className={styles.loginInput + " " + styles.registration_input}
                                      onChange={changeHandler} customInputHeight={"50px"}/>
                </div>
                <div className={styles.inputdiv}>
                    <label htmlFor="pass" className={styles.label}>
                        {lang.confirmPassLabel}
                    </label>
                    <CustomLoginInput type="password" name="confirmPassword" customInputHeight={"50px"}
                                      className={styles.loginInput + " " + styles.registration_input}
                                      onChange={(event) => {
                                          SetConfirmPassword(event.target.value);

                                      }}/>
                </div>
                {
                    language === "RU"
                        ?
                        <button
                            className={styles.login_popup_btn}
                            onClick={() => {
                                handleSubmit()
                            }}>
                            {lang.signUpBtn}
                        </button>
                        :
                        <button
                            className={styles.login_btn}

                        >
                            <div className={styles.plate_on_flour}>
                                <div className={styles.flour_img}/>
                                <div
                                    className={styles.plate_img}
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                />
                                <div className={styles.login_text} onClick={() => {
                                    handleSubmit()
                                }}>
                                    {lang.signUpBtn}
                                </div>
                            </div>
                        </button>
                }

                <div className={styles.register_btn}>
                    <span>
                       {lang.navigateSignIn.regular}
                    </span>
                    <button onClick={() => {
                        changeSection(1);
                    }}>
                        {lang.navigateSignIn.red}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default RegistrationSection;
