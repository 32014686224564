import OrdersSection from "../../component/organism/Orders";

const Orders = () => {

    return(
        <div>
            <OrdersSection />
        </div>
    );
};

export default Orders;