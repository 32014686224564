import styles from "./style.module.scss";
import paymentMethod from "../../../../assets/icons/CheckoutPage/PaymentMethod.png";
import idramIcon from "../../../../assets/icons/CheckoutPage/idram.png";
import telcellIcon from "../../../../assets/icons/CheckoutPage/telcell.png";

import {useLocation, useNavigate} from "react-router-dom";
import CheckoutProductSectionMobile from "../../../molecul/CheckoutPage/ProductSection/Mobile"
import {useEffect, useRef, useState} from "react";
import CustomDatePicker from "../../../atom/CustomDatePicker";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import totalPriceBg from "../../../../assets/backgronds/cart/totalPriceBg.png";
import useRemoveCartItem from "../../../../hooks/product/cart/useRemoveCartItem";
import md5 from "blueimp-md5";

import {notifyUser} from "../../../../redux/features/popup/notifyMessage";
import deliveryIcon from "../../../../assets/icons/ProductPage/CommonIcons/deliveryIcon.png";

const CREATE_ORDER = gql`
    mutation Mutation(
        $orderInput: OrderInput
    ) {   
        createNewOrder(
          orderInput: $orderInput
        ) {
            _id
            orderNumber
            date
            totalPrice
            deliveryTime
            redirectUrl
        }
    }
`;

const GET_CART_ITEMS_WITH_IDS = gql`
  query($userId: ID!, $cartItemIds: [ID!]!) {
    userCartProductsWithIds(userId: $userId, _ids: $cartItemIds){
        _id
        price
        quantity
        productId
        productImages
        productTitle{
            en
            am
            ru
        }
        defaultPrice
        state
    }
  }
`;


const CheckoutSectionMobile = ({lang}) => {
    const {removeCartItem} = useRemoveCartItem();
    const navigate = useNavigate();
    const {state} = useLocation();
    const user = useSelector(state => state.user).user;
    const userId = user ? user._id : "without account";
    const [regionDropdownOpen, setRegionDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const navigateData = state && !state.productIds && state.map((item) => {
        delete item['__typename'];
        delete item['_id'];
        return item;
    });

    const [productData, setProductData] = useState(state && state.productIds ? [] : navigateData);
    const [totalPrice, setTotalPrice] = useState(0);


    const [getProducts, {error, loading, data, refetch}] = useLazyQuery(GET_CART_ITEMS_WITH_IDS, {
        fetchPolicy:'network-only',
    });


    useEffect(() => {
        setTimeout(() => window.scrollTo(0, 0), 10);
        refetch();

        if ((!state || !state.productIds) && !navigateData) {
            navigate('/');
        }
        if ((productData === [] || (productData && productData.length === 0)) && !!state.productIds) {
            const cartIds = state.productIds;
            if(cartIds){
                setInitialState({
                    ...initialState, ['cartProductsIds']: cartIds,
                });
                getProducts({
                    variables: {
                        userId: userId, cartItemIds: cartIds,
                    }
                })
            }

        } else if (state && state[0]) {
            const deliveryPrice = Number(state[0].price) >= 30000 ? 0 : 1000;
            setTotalPrice(Number(state[0].price) + deliveryPrice);
            setInitialState({
                ...initialState, ['totalPrice']: Number(state[0].price) + deliveryPrice,
            });
        }
    }, []);


    const [selectedPaymentOption, setSelectedPaymentOption] = useState('withCash');
    const [formError, setFormError] = useState(false);


    const currentDate = new Date();


    const allFieldsRef = useRef([])

    const pushRef = (el) => {
        if (el && !allFieldsRef.current.includes(el)) {
            allFieldsRef.current.push(el);
        }
    };





    // date settings
    currentDate.setDate(currentDate.getDate());


// Minimum date: current date
    const minDate = currentDate.toISOString().substring(0, 10);

// Maximum date: end of next year

    currentDate.setHours(currentDate.getHours() + 2); // add 2 hours to the current time

// Minimum time: 2 hours ahead of the current time
    const minTime = currentDate.toTimeString().slice(0, 5);

    const productIds = state && !state.productIds ? state !== {} && state.map(item => item.productId) : '';
    const [initialState, setInitialState] = useState({
        userId: userId,
        productIds: productIds,
        totalPrice: totalPrice,
        productData: productData,
        userName: user ? user.fullName : '',
        paymentMethod: selectedPaymentOption,
        tel: user ? user.tel : '',
        email: user ? user.email : '',
        region: 'Yerevan',
        address: '',
        deliveryTime: dayjs(minDate + "T" + minTime).$d.toLocaleString(),
        notes: '',
    })
    const changeHandler = event => {
        setInitialState({...initialState, [event.target.name]: event.target.value});
    };
    const checkFieldsValue = () => {
        allFieldsRef.current.map((el) => {
            if (!el.value && el.name !== 'email' && el.name !== 'tel' && !el.getElementsByTagName("input").tel) {
                el.style.border = "1px solid red";
            } else if (el.name === 'email' && (!el.value || !isValidEmail(el.value))) {
                el.style.border = "1px solid red";
            } else if (el.getElementsByTagName("input").tel  && el.getElementsByTagName("input").tel.value.length < 7) {
                el.style.border = "1px solid red";
            } else {
                el.style.border = "1px solid #000000";
            }
        });
    };
    useEffect(() => {
        if (!loading && data && data.userCartProductsWithIds) {
            setProductData(data.userCartProductsWithIds);
            let totalSum = 0
            data.userCartProductsWithIds.map(item => {
                totalSum = totalSum + Number(item.price);

            });
            const deliveryPrice = totalSum >= 30000 ? 0 : 1000;
            setTotalPrice(totalSum + deliveryPrice);
            const cartData = JSON.parse(JSON.stringify(data.userCartProductsWithIds));
            cartData.map(object => {
                delete object['_id'];
                delete object['__typename'];
            });
            const ids = data && data.userCartProductsWithIds.map(item => item.productId);
            setInitialState({
                ...initialState, ['productData']: cartData, ['productIds']: ids, ['totalPrice']: totalSum + deliveryPrice
            });
        }
    }, [data, loading]);

    const handleOptionClick = (option) => {
        setSelectedPaymentOption(option);
        setInitialState({
            ...initialState, ['paymentMethod']: option,
        });
    };
    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const inputChangeHandler = (e) => {
        changeHandler(e);
        if (formError) {
            checkFieldsValue();
        }
    };
    const [createNewOrder, {loading: btnDisabled}] = useMutation(CREATE_ORDER, {
        update(proxy, {data: {createNewOrder: orderData}}) {
            if(initialState.paymentMethod === "withCash"){
                const handleBeforeUnload = () => {
                    window.history.replaceState({}, '', window.location.href);
                    window.location.replace('https://meathouse.am/'); // Replace with your home page URL
                };

                window.addEventListener('beforeunload', handleBeforeUnload);
                navigate("/order_done", {
                    state: {
                        navigateData: orderData,
                    }
                });
                if (state.productIds) {
                    state.productIds.map(async (cartItem) => {
                        await removeCartItem({
                            variables: {
                                removeCartItemInput: {
                                    cartItemId: cartItem,
                                },
                            },
                        });
                    });
                }
            }else if(initialState.paymentMethod === "cashLess"){
                const handleBeforeUnload = () => {
                    window.history.replaceState({}, '', window.location.href);
                    window.location.replace('https://meathouse.am/'); // Replace with your home page URL
                };

                window.addEventListener('beforeunload', handleBeforeUnload);
                window.location.replace(orderData.redirectUrl);
            }
        }, onError({graphQLErrors}) {

            if (graphQLErrors && graphQLErrors[0].message) {
                dispatch(notifyUser({
                    barOpen: true,
                    message: graphQLErrors[0].message,
                }));
            }
        }
    });
    const randomNumber = () => {
        return Math.floor(10000000 + Math.random() * 90000000);
    };
    const crateAnOrder = async () => {
        const initialStateCopy = {...initialState};
        delete initialStateCopy["notes"];

        const containsFalsy = Object.values(initialStateCopy).some(item => !item || (Array.isArray(item) && item.length === 0));
        if (containsFalsy || !isValidEmail(initialState.email) || initialState.tel.length !== 8) {
            const formTop = allFieldsRef.current[0].getBoundingClientRect().top;
            window.scrollTo(0, window.pageYOffset + formTop - 50);
            setFormError(true);
            checkFieldsValue();
        } else {

            if (initialState.paymentMethod === "withCash" && !btnDisabled) {
                await createNewOrder({
                    variables: {
                        orderInput: initialState,
                    },
                })
            } else if (initialState.paymentMethod === 'telcell') {
                let productTitle = "";
                initialState.productData.map(item => {
                    productTitle = productTitle + item.productTitle.en + " ";
                });
                const transactionId = randomNumber();
                setInitialState({
                    ...initialState,
                    transactionId: transactionId,
                });
                const data = {
                    action: "PostInvoice",
                    issuer: "meathouse.am@gmail.com",
                    currency: "֏",
                    price: initialState.totalPrice.toString(),
                    product: btoa(JSON.stringify(productTitle)),
                    issuer_id: btoa(JSON.stringify(transactionId)),
                    valid_days: "1",
                    security_code: md5(
                        "FjesAKOE8UxZsbWU6fuSuazQbCr1CVWeMaZLZ9v2wKLbCsjgPKiPF9dPpL94dNp8rxdGTlbjqSwcKhkp4Sm9Zkfv1XijFLDmwsnqQgfhpnlMwPXY5iIEpGlJtnN13bhb"
                        + "meathouse.am@gmail.com"
                        + "֏"
                        + initialState.totalPrice.toString()
                        + btoa(JSON.stringify(productTitle))
                        + btoa(JSON.stringify(transactionId))
                        + "1"
                    )
                };

                // Create a new FormData object
                const formData = new FormData();

                // Append the data to the FormData object
                for (const key in data) {
                    formData.append(key, data[key]);
                }

                // Create a hidden form element
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = 'https://telcellmoney.am/invoices'; // Replace with the actual bank URL

                // Append the FormData object to the form
                for (const [key, value] of formData) {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = key;
                    input.value = value;
                    form.appendChild(input);
                }

                document.body.appendChild(form);
                await createNewOrder({
                    variables: {
                        orderInput: {
                            ...initialState,
                            transactionId: transactionId,
                        },
                    },
                });
                // Remove history entry and redirect to home page
                const handleBeforeUnload = () => {
                    window.history.replaceState({}, '', window.location.href);
                    window.location.replace('https://meathouse.am/'); // Replace with your home page URL
                };

                window.addEventListener('beforeunload', handleBeforeUnload);

                await form.submit();


            } else if (initialState.paymentMethod === "cashLess") {
                await createNewOrder({
                    variables: {
                        orderInput: initialState,
                    },
                });
            }}
    }
    return (
        <div className={styles.cart_section}>
            <div className={styles.main_order_section_mobile}>
                <div className={styles.first_section}>
                    <div className={styles.section_heading}>
                        <div>
                            <hr/>
                            <h2>
                                {lang.heading}
                            </h2>
                        </div>
                    </div>
                    <div className={styles.delivery_price}>
                        <img src={deliveryIcon} alt={'delivery'} className={styles.delivery_icon}/>
                        {
                            totalPrice >= 30000
                                ?
                                lang.free
                                :
                                1000 + " ֏"
                        }
                    </div>
                    <CheckoutProductSectionMobile data={productData}/>
                </div>
                <div className={styles.payment_method_section_mobile}>
                    <div className={styles.order_heading}>
                        <img src={paymentMethod} alt={"orderIcon"}/>
                        {lang.paymentMethod}
                    </div>
                    <div>
                        <label className={styles.radio_btn + ' ' + styles.radio_margin} onClick={() => {
                            handleOptionClick('withCash');
                        }}>
                            <input name="radio" type="radio" checked={selectedPaymentOption === "withCash"}
                                   readOnly={true}/>
                            <span>  {lang.cash}</span>
                        </label>

                        <label className={styles.radio_btn + ' ' + styles.radio_margin} onClick={() => {
                            handleOptionClick('cashLess');
                        }}>
                            <input type="radio" checked={selectedPaymentOption === "cashLess"} readOnly={true}/>
                            <span> {lang.cashless}</span>
                        </label>

                        <div className={styles.cash_less_idram_div} onClick={() => {
                            handleOptionClick('idram');
                        }}>
                            <label className={styles.radio_btn}>
                                <input name="radio" type="radio" checked={selectedPaymentOption === "idram"}
                                       readOnly={true}/>
                                <span></span>
                            </label>
                            <img src={idramIcon} alt={"idramIcon"}/>
                        </div>
                        <div className={styles.cash_less_idram_div} onClick={() => {
                            handleOptionClick('telcell');
                        }}>
                            <label className={styles.radio_btn}>
                                <input type="radio" checked={selectedPaymentOption === "telcell"} readOnly={true}/>
                                <span/>
                            </label>
                            <img src={telcellIcon} alt={"telcellIcon"}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.order_form_heading}>
                <div>
                    <h2>
                        {lang.formHeading}
                    </h2>
                    <hr/>
                </div>
            </div>
            <div className={styles.order_form_mobile}>
                <div className={styles.input_div}>
                    <label>
                        {lang.formName}
                    </label>
                    <input
                        type={"text"}
                        name={"userName"}
                        onChange={inputChangeHandler}
                        defaultValue={user ? user.fullName : ''}
                        ref={pushRef}
                    />
                    {formError && initialState.userName.length === 0 && <span>{lang.requiredField}</span>}
                </div>
                <div className={styles.input_div}>
                    <label>
                        {lang.formNumber}
                    </label>
                    <div className={styles.tel_input_div} ref={pushRef}>
                        <span className={styles.tel_span} >+374</span>
                        <input
                            type={"number"}
                            name={"tel"}
                            onChange={inputChangeHandler}
                            defaultValue={user ? user.tel : ''}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === '.' || e.key === '+') e.preventDefault();
                                else if (e.target.value.length > 7 && e.key !== 'Backspace' && e.key !== 'Control' && e.key !== 'a') e.preventDefault();
                            }}
                        />
                    </div>
                    {formError && initialState.tel.length === 0 && <span>{lang.requiredField}</span>}
                    {formError && initialState.tel.length !== 8 && initialState.tel.length !== 0 &&
                        <span>{lang.validPhoneNumber}</span>
                    }
                </div>
                <div className={styles.input_div}>
                    <label>
                        {lang.formEmail}
                    </label>
                    <input
                        type={"text"}
                        name={"email"}
                        onChange={inputChangeHandler}
                        defaultValue={user ? user.email : ''}
                        ref={pushRef}
                    />
                    {formError && initialState.email.length === 0 && <span>{lang.requiredField}</span>}
                    {formError && !isValidEmail(initialState.email) && initialState.email.length !== 0 &&
                        <span>{lang.validEmail}</span>}
                </div>


                <div className={styles.input_div}>
                    <label>
                        {lang.formRegion}
                    </label>
                    <input
                        type={"text"}
                        name={"region"}
                        // onChange={inputChangeHandler}
                        ref={pushRef}
                        // onClick={() => {
                        //     setRegionDropdownOpen(true)
                        // }}
                        className={styles.region_dropdown_toggle}
                        value={lang.region}
                        readOnly={true}
                    />
                    {formError && initialState.region.length === 0 && <span>{lang.requiredField}</span>}
                </div>
                <div className={styles.input_div}>
                    <label>
                        {lang.formAddress}
                    </label>
                    <input
                        type={"text"}
                        name={"address"}
                        onChange={inputChangeHandler}
                        ref={pushRef}
                    />
                    {formError && initialState.address.length === 0 && <span>{lang.requiredField}</span>}
                </div>
                <div className={styles.input_date_div}>
                    <label>
                        {lang.formTime}
                    </label>
                    <CustomDatePicker
                        date={dayjs(minDate + "T" + minTime)}
                        changeStateValue={(value) => {
                            setInitialState({
                                ...initialState, ['deliveryTime']: value.$d.toLocaleString(),
                            });
                        }}/>
                </div>

                <div className={styles.input_div}>
                    <label>
                        {lang.formNote}
                    </label>
                    <textarea name={"notes"} onChange={changeHandler}/>
                </div>

            </div>
            <div className={styles.total_price_div}>
                    <span>
                        {lang.amountToBePaid}
                    </span>

                <div className={styles.total_price}>
                    <span>{totalPrice} ֏</span>
                    <img src={totalPriceBg} alt={"totalPrice"}/>
                </div>
            </div>
            <div className={styles.nav_btn_div}>

                <button
                    className={styles.checkout_btn}
                    onClick={crateAnOrder}
                    disabled={btnDisabled}
                >
                    {lang.checkOutBtn}
                </button>
            </div>
        </div>
    );
};

export default CheckoutSectionMobile;

